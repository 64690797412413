import { FeedbackProvider } from 'providers';
import { LanguageProvider } from 'providers/language.provider';
import React, { JSXElementConstructor } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withContext = (WrappedComponent: JSXElementConstructor<any>) => (props: any) => (
	<FeedbackProvider>
		<LanguageProvider>
			<WrappedComponent {...props} />
		</LanguageProvider>
	</FeedbackProvider>
);

export default withContext;
