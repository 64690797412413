import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DashboardPage from './DashboardPage';
import { IDashboardSummary } from 'data/models';
import { useFeedback, useTitleBar } from 'providers';
import { useEffectCustom } from 'components/hooks';
import { getDashboardData } from 'data/api/requests';

const Dashboard = () => {
	const [data, setData] = useState<IDashboardSummary>();
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { t } = useTranslation('translations');
	const { addToast } = useFeedback();

	useEffect(() => {
		setTitle(t('dashboard'));
	}, [t, setTitle]);

	useEffectCustom(() => {
		const getData = () => {
			setGlobalLoading(true);
			getDashboardData()
				.then((res) => {
					setData(res.data);
				})
				.catch(({ response }) => { addToast({ message: response.data.message, error: true }); })
				.finally(() => setGlobalLoading(false));
		};
		getData();
	}, []);

	return <DashboardPage data={data} />;
};

export default Dashboard;
