import {
	ApiResponse,
	IChainStore,
	IChainStoreReq,
	IListDataControl,
	IPagination,
} from 'data/models';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';
import { uploadFile } from './file.service';

export const getChainStores = async (props: IListDataControl, signal?: AbortSignal):
	Promise<ApiResponse<IPagination<IChainStore[]>>> => {
	const resp = await reqGet({
		url: Endpoint.CHAIN_STORES.format(props.page, props.size, props.search),
		signal,
	});
	const data = resp.data as ApiResponse<IPagination<IChainStore[]>>;
	data.statusCode = resp.status;
	return data;
};

export const getChainStoreById = async (chainStoreId: string, signal: AbortSignal):
	Promise<ApiResponse<IChainStore>> => {
	const resp = await reqGet({
		url: Endpoint.CHAIN_STORES_BY_ID.format(chainStoreId),
		signal,
	});
	const data = resp.data as ApiResponse<IChainStore>;
	data.statusCode = resp.status;
	return data;
};

export const chainStoreStatus = async (chainStoreId: string): Promise<ApiResponse<IChainStore>> => {
	const resp = await reqPost({
		url: Endpoint.CHAIN_STORES_STATUS.format(chainStoreId),
	});
	const data = resp.data as ApiResponse<IChainStore>;
	data.statusCode = resp.status;
	return data;
};

export const addChainStore = async (chainStore: IChainStoreReq):
	Promise<ApiResponse<IChainStore>> => {
	let { coverImage, profileImage } = chainStore;
	if (typeof coverImage === 'object') {
		if (coverImage.size) {
			const data = new FormData();
			data.append('file', coverImage);
			const res = await uploadFile(data);
			coverImage = res.data.file;
		}
	}
	if (typeof profileImage === 'object') {
		if (profileImage.size) {
			const data = new FormData();
			data.append('file', profileImage);
			const res = await uploadFile(data);
			profileImage = res.data.file;
		}
	}
	const resp = await reqPost({
		url: Endpoint.ADD_CHAIN_STORE,
		data: { ...chainStore, coverImage, profileImage },
	});
	const data = resp.data as ApiResponse<IChainStore>;
	data.statusCode = resp.status;
	return data;
};

export const editChainStore = async (chainStoreId: string, chainStore: IChainStoreReq):
	Promise<ApiResponse<IChainStore>> => {
	let { coverImage, profileImage } = chainStore;
	const { name, description } = chainStore;
	if (typeof coverImage === 'object') {
		if (coverImage.size) {
			const data = new FormData();
			data.append('file', coverImage);
			const res = await uploadFile(data);
			coverImage = res.data.file;
		}
	}
	if (typeof profileImage === 'object') {
		if (profileImage.size) {
			const data = new FormData();
			data.append('file', profileImage);
			const res = await uploadFile(data);
			profileImage = res.data.file;
		}
	}
	const resp = await reqPost({
		url: Endpoint.CHAIN_STORES_BY_ID.format(chainStoreId),
		data: {
			chainStore: name, answer: description, coverImage, profileImage,
		},
	});
	const data = resp.data as ApiResponse<IChainStore>;
	data.statusCode = resp.status;
	return data;
};
