import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CreateChainStorePage from './CreateChainStorePage';
import { useFeedback, useTitleBar } from 'providers';
import { IChainStoreReq } from 'data/models';
import { addChainStore, editChainStore, getChainStoreById } from 'data/api/requests';
import { AppRoutes } from 'components/routes/app-links.routes';

const CreateChainStore = () => {
	const { setTitle } = useTitleBar();
	const { t } = useTranslation('translations');
	const { id } = useParams();
	const { addToast } = useFeedback();
	const navigate = useNavigate();

	const [chainStore, setChainStore] = useState({ name: '', description: '' } as IChainStoreReq);
	const [errors, setErrors] = useState({
		name: '',
		description: '',
		profileImage: '',
		coverImage: '',
	} as Partial<IChainStoreReq>);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (id) {
			setTitle(`${t('edit')} ${t('network')}`);
		} else {
			setTitle(`${t('new')} ${t('network')}`);
		}
	}, [setTitle, t, id]);

	useEffect(() => {
		const abortCtrl = new AbortController();
		if (id) {
			getChainStoreById(id, abortCtrl.signal).then(({ data }) => setChainStore({
				name: data.name,
				description: data.description,
				profileImage: data.profileImage,
				coverImage: data.coverImage,
			}));
		}
		return () => abortCtrl.abort();
	}, [id]);

	const handleChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		if (event?.target?.files) {
			setChainStore({ ...chainStore, [event.target.name]: event.target?.files[0] });
			setErrors({ ...errors, [event.target.name]: '' });
			return;
		}
		setChainStore({ ...chainStore, [event.target.name]: event.target.value });
		setErrors({ ...errors, [event.target.name]: '' });
	};

	const submit = () => {
		setLoading(true);
		if (id) {
			editChainStore(id, chainStore)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.CHAIN_STORES);
				})
				.catch(({ response }) => { addToast({ message: response.data.message, error: true }); })
				.finally(() => setLoading(false));
		} else {
			addChainStore(chainStore)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.CHAIN_STORES);
				})
				.catch(({ response }) => addToast({ message: response.data.message, error: true }))
				.finally(() => setLoading(false));
		}
	};

	const validate = () => {
		if (!chainStore.name) {
			return setErrors({ ...errors, name: t('emptyField') });
		}
		if (!chainStore.description) {
			return setErrors({ ...errors, description: t('emptyField') });
		}
		if (!chainStore.profileImage) {
			return setErrors({ ...errors, profileImage: t('emptyField') });
		}
		if (!chainStore.coverImage) {
			return setErrors({ ...errors, coverImage: t('emptyField') });
		}
		return submit();
	};

	return (
		<CreateChainStorePage
			chainstore={chainStore}
			errors={errors}
			onChange={handleChange}
			submit={validate}
			loading={loading}
		/>
	);
};

export default CreateChainStore;
