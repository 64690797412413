import { reqPost } from '../request.api';
import Endpoint from '../endpoints.api';
import { ApiResponse, IUser } from 'data/models';

export const login = async (payload: { email: string; password: string; deviceUniqueId: string }):
   Promise<ApiResponse<IUser>> => {
	const dataToUpload = {
		...payload,
		deviceTypeId: 3, // TODO In future we wil get the device ID and
		platformId: 3,
	};
	const resp = await reqPost({
		url: Endpoint.LOGIN,
		data: { ...dataToUpload },
	});
	const data = resp.data as ApiResponse<IUser>;
	data.statusCode = resp?.status;
	return data;
};

export const changePassword = async (currentPassword: string, newPassword: string):
	Promise<ApiResponse<void>> => {
	const resp = await reqPost({
		url: Endpoint.CHANGE_PASSWORD,
		data: { currentPassword, newPassword },
	});
	const data = resp.data as ApiResponse<void>;
	data.statusCode = resp?.status;
	return data;
};

export const forgotPassword = async (email: string): Promise<ApiResponse<void>> => {
	const resp = await reqPost({
		url: Endpoint.FORGOT_PASSWORD,
		data: { email },
	});
	const data = resp.data as ApiResponse<void>;
	data.statusCode = resp.status;
	return data;
};

export const logout = async (): Promise<ApiResponse<void>> => {
	const resp = await reqPost({
		url: Endpoint.LOGOUT,
	});
	const data = resp.data as ApiResponse<void>;
	data.statusCode = resp?.status;
	return data;
};

export const refreshToken = async (token: string): Promise<ApiResponse<IUser>> => {
	const resp = await reqPost({
		url: Endpoint.REFRESH_TOKEN,
		data: { refreshToken: token },
	});
	const data = resp.data as ApiResponse<IUser>;
	data.statusCode = resp.status;
	return data;
};
