/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-return-assign */
import {
	ApiResponse,
	IAudienceFilterElement,
	ICoupon,
	ICouponReport,
	ICouponReportReq,
	ICouponRequest,
	ICreateCouponReq,
	IDefineCouponAudienceReq,
	IListDataControl,
	INameAndId,
	IOrganization,
	IPagination,
} from 'data/models';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';
import { uploadFile } from './file.service';
import { encodeDate, normalizeCurrency } from 'utils/helpers';
import { replaceComma } from 'utils/masks';

export const getCouponReport = async (props: ICouponReportReq):
   Promise<ApiResponse<ICouponReport[]>> => {
	const { initialDate, finalDate, categories } = props;
	const encodedInitialDate = encodeDate(initialDate);
	const encodedFinalDate = encodeDate(finalDate);
	let categoriess = '';
	categories.map((value) => (categoriess += `&Categories=${value.id}`));

	const resp = await reqGet({
		url: Endpoint.GET_COUPONS_REPORT.format(encodedInitialDate, encodedFinalDate, categoriess),
	});
	const data = resp.data as ApiResponse<ICouponReport[]>;
	data.statusCode = resp?.status;
	return data;
};

export const exportCouponReport = async (props: ICouponReportReq):
   Promise<ApiResponse<IPagination<ICouponReport[]>>> => {
	const { initialDate, finalDate, categories } = props;
	let categoriess = '';
	categories.map((value, index) => (categoriess += `&categories${index}=${value}`));

	const resp = await reqGet({
		url: Endpoint.EXPORT_COUPONS_REPORT.format(initialDate, finalDate, categoriess),
	});
	const data = resp.data as ApiResponse<IPagination<ICouponReport[]>>;
	data.statusCode = resp?.status;
	return data;
};

export const getCoupons = async (props: IListDataControl):
   Promise<ApiResponse<IPagination<ICoupon[]>>> => {
	const { page, size, search } = props;

	const resp = await reqGet({
		url: Endpoint.GET_COUPONS.format(page, size, search),
	});
	const data = resp.data as ApiResponse<IPagination<ICoupon[]>>;
	data.statusCode = resp?.status;
	return data;
};

export const getCouponById = async (couponId: string, signal?: AbortSignal):
   Promise<ApiResponse<ICoupon>> => {
	const resp = await reqGet({
		url: Endpoint.EDIT_COUPON.format(couponId),
		signal,
	});
	const data = resp.data as ApiResponse<ICoupon>;
	data.data.organizations = data.data.organizations.reduce((array, el) => {
		array.push({ ...el, name: el.fantasyName });
		return array;
	}, [] as IOrganization[]);
	data.statusCode = resp?.status;
	return data;
};

export const changeCouponStatus = async (couponId?: number): Promise<ApiResponse<ICoupon>> => {
	const resp = await reqPost({
		url: Endpoint.CHANGE_COUPON_STATUS.format(couponId),
	});
	const data = resp.data as ApiResponse<ICoupon>;
	data.statusCode = resp?.status;
	return data;
};

export const deleteCouponRequest = async (couponUserId: number):
	Promise<ApiResponse<ICouponRequest>> => {
	const resp = await reqPost({
		url: Endpoint.DELETE_COUPON_REQUEST.format(couponUserId),
		data: { statusId: 1 },
	});
	const data = resp.data as ApiResponse<ICouponRequest>;
	data.statusCode = resp?.status;
	return data;
};

export const getCouponRequests = async (props: IListDataControl):
   Promise<ApiResponse<IPagination<ICouponRequest[]>>> => {
	const { page, size, search } = props;

	const resp = await reqGet({
		url: Endpoint.GET_COUPONS_REQUESTS.format(page, size, search),
	});
	const data = resp.data as ApiResponse<IPagination<ICouponRequest[]>>;
	data.statusCode = resp?.status;
	return data;
};

export const sendCouponToUser = async (userId: number | undefined, couponId: number | undefined):
   Promise<ApiResponse<void>> => {
	const resp = await reqPost({
		url: Endpoint.SEND_COUPON_TO_USER.format(couponId, userId),
	});
	const data = resp.data as ApiResponse<void>;
	data.statusCode = resp?.status;
	return data;
};

export const createCoupon = async (model: ICreateCouponReq): Promise<ApiResponse<ICoupon>> => {
	let downlink = '';

	if (model.file.size) {
		const data = new FormData();
		data.append('file', model.file);
		const res = await uploadFile(data);
		downlink = res.data.file;
	}
	const dataToUplaod = {
		...model,
		startDate: encodeDate(model.startDate),
		endDate: encodeDate(model.endDate),
		organizations: model.organizations.reduce((array, el) => {
			array.push(el.id);
			return array;
		}, [] as number[]),
		minPurchaseAmount: normalizeCurrency(model.minPurchaseAmount),
		maxPurchaseAmount: normalizeCurrency(model.maxPurchaseAmount),
		cost: normalizeCurrency(model.cost),
		amount: replaceComma(model.amount),
		banner: downlink,
		regulation: model.regulation.toString('html'),
	};

	const resp = await reqPost({
		url: Endpoint.COUPONS,
		data: dataToUplaod,
	});
	const data = resp.data as ApiResponse<ICoupon>;
	data.statusCode = resp?.status;
	return data;
};

export const editCoupon = async (couponId: number | string, model: ICreateCouponReq):
   Promise<ApiResponse<ICoupon>> => {
	let downlink = model.banner ? model.banner : '';
	if (model.file.size) {
		const data = new FormData();
		data.append('file', model.file);
		const res = await uploadFile(data);
		if (res.statusCode !== 200) {
			downlink = '';
		} else {
			downlink = res.data.file;
		}
	}
	const dataToUplaod: any = {
		...model,
		startDate: encodeDate(model.startDate),
		endDate: encodeDate(model.endDate),
		organizations: model.organizations.reduce((array, el) => {
			array.push(el.id);
			return array;
		}, [] as number[]),
		minPurchaseAmount: normalizeCurrency(model.minPurchaseAmount),
		maxPurchaseAmount: normalizeCurrency(model.maxPurchaseAmount),
		cost: normalizeCurrency(model.cost),
		amount: replaceComma(model.amount),
		banner: downlink,
		regulation: model.regulation.toString('html'),
		couponId,
	};
	delete dataToUplaod.file;

	const resp = await reqPost({
		url: Endpoint.EDIT_COUPON.format(couponId),
		data: dataToUplaod,
	});
	const data = resp.data as ApiResponse<any>;
	data.statusCode = resp?.status;
	return data;
};

export const getCurrentAudience = async (couponId: string): Promise<ApiResponse<INameAndId[]>> => {
	const resp = await reqGet({
		url: Endpoint.AUDIENCE_BY_COUPON_ID.format(couponId),
	});
	const data = resp.data as ApiResponse<INameAndId[]>;
	data.statusCode = resp.status;
	return data;
};

export const getAudienceByFilter = async (audienceFilter: IDefineCouponAudienceReq):
	Promise<ApiResponse<INameAndId[]>> => {
	let queryString = `?model=${audienceFilter.hasVehicle ? audienceFilter.model : ''}`;
	if (audienceFilter.genders.length > 0) {
		let genderString = `${audienceFilter.genders.map((element) => `&genders=${element.id}`)}`;
		genderString = genderString.replaceAll(',', '');
		queryString += genderString;
	}
	if (audienceFilter.tags.length > 0) {
		let tagsString = `${audienceFilter.tags.map((el) => `&tags=${el.id}`)}`;
		tagsString = tagsString.replaceAll(',', '');
		queryString += tagsString;
	}
	if (audienceFilter.hasVehicle) {
		queryString += '&hasCar=true';
	} else {
		queryString += '&hasCar=false';
	}
	if (audienceFilter.brands.length > 0) {
		let brandQuery = `${audienceFilter.brands.map((el) => `&brands=${el}`)}`;
		brandQuery = brandQuery.replaceAll(',', '');
		queryString += audienceFilter.hasVehicle ? brandQuery : '';
	}
	const resp = await reqGet({
		url: Endpoint.AUDIENCE_BY_FILTER + queryString,
	});
	const data = resp.data as ApiResponse<INameAndId[]>;
	data.statusCode = resp.status;
	return data;
};

export const getAudienceFilterElements = async (couponId: string):
	Promise<ApiResponse<IAudienceFilterElement>> => {
	const resp = await reqGet({ url: Endpoint.AUDIENCE_FILTER_ELEMENTS.format(couponId) });
	const data = resp.data as ApiResponse<IAudienceFilterElement>;
	data.statusCode = resp.status;
	return data;
};

export const saveAudience = async (
	couponId: string,
	filters: IDefineCouponAudienceReq,
	audience?: INameAndId[],
):
	Promise<ApiResponse<void>> => {
	const dataToUplaod = {
		genders: filters.genders.reduce((newArr, el) => {
			newArr.push(el.id); return newArr;
		}, [] as number[]),
		hasCar: filters.hasVehicle,
		tags: filters.tags.reduce((newArr, el) => {
			newArr.push(el.id); return newArr;
		}, [] as number[]),
		brands: filters.brands,
		model: filters.model,
		audience: audience?.reduce((newArr, el) => {
			newArr.push(el.id); return newArr;
		}, [] as number[]),
	};
	const resp = await reqPost({
		url: Endpoint.SAVE_AUDIENCE.format(couponId),
		data: { ...dataToUplaod },
	});
	const data = resp.data as ApiResponse<void>;
	data.statusCode = resp.status;
	return data;
};
