/* eslint-disable no-param-reassign */
import React from 'react';
import {
	Box, CircularProgress, Stack,
} from '@mui/material';
import { placeholderImage } from 'assets/image';

export interface ImageProps {
   src: string;
   height?: number | string;
   width?: number | string;
   alt: string;
   fit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
   style?: React.CSSProperties;
   altImage?: string
}

const Image = (props: ImageProps) => {
	const {
		src, height, width, alt, fit, style, altImage,
	} = props;
	const [loading, setLoading] = React.useState(true);
	const [imagePlaceholder, setImagePlaceholder] = React.useState('');

	React.useEffect(() => {
		if (altImage) {
			setImagePlaceholder(altImage);
		} else {
			setImagePlaceholder(placeholderImage);
		}
	}, [altImage]);

	const onLoad = () => {
		setLoading(false);
	};
	const onLoadStart = () => {
		setLoading(true);
	};

	return (
		<Box sx={{ height, width }}>
			{loading && (
				<Stack
					direction="column"
					alignItems="center"
					justifyContent="center"
					sx={{
						position: 'absolute',
						width,
						height,
						zIndex: 100,
					}}
				>
					<CircularProgress color="info" size={20} thickness={2.5} />
				</Stack>
			)}
			<img
				src={src}
				height={height}
				width={width}
				alt={alt}
				style={{ objectFit: fit ?? 'fill', ...style }}
				onError={({ currentTarget }) => {
					currentTarget.onerror = null;
					currentTarget.src = imagePlaceholder;
				}}
				onLoad={onLoad}
				// eslint-disable-next-line react/no-unknown-property
				onLoadStart={onLoadStart}
			/>
		</Box>
	);
};

export default Image;
