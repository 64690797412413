/* eslint-disable react/no-unstable-nested-components */
import {
	Route,
	Routes,
	BrowserRouter,
	Navigate,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import { AppRoutes } from './app-links.routes';
import React from 'react';
import { useAuth } from 'providers';
import { useEffectCustom } from '../hooks';
import Login from '../pages/login';
import ListUsers from '../pages/users/list';
import CreateUser from '../pages/users/create';
import PageNotFound from '../pages/commons/404';
import Main from '../pages/main';
import ListCards from '../pages/cards/list';
import CreateCard from '../pages/cards/create';
import IssueCard from '../pages/cards/issue-card';
import ForgotPassword from '../pages/forgot-password';
import Dashboard from '../pages/dashboard';
import Administrators from '../pages/administrators';
import ChangeEmail from '../pages/change-email';
import ResetPassword from '../pages/reset-password';
import CouponList from '../pages/coupons/list';
import CreateCoupon from '../pages/coupons/create';
import RequestedCoupons from '../pages/coupons/requested';
import UsersReport from '../pages/reports/users-report';
import CouponsReport from '../pages/reports/coupon-report';
import TransactionReport from '../pages/reports/transaction-report';
import PromoteToAdm from '../pages/users/promote';
import SendMessage from '../pages/users/send-message';
import ListOrganizations from '../pages/organizations/list';
import CreateOrganization from '../pages/organizations/create';
import ListChainStore from '../pages/chain-stores/list';
import CreateChainStore from '../pages/chain-stores/create';
import ListPartners from '../pages/partners/list';
import CreatePartner from '../pages/partners/create';
import ListCostCenter from '../pages/cost-centers/list';
import CreateCostCenter from '../pages/cost-centers/create';
import Transactions from '../pages/transactions';
import TermsList from '../pages/terms/list';
import CreateTerm from '../pages/terms/create';

const AppRouter = () => {
	const RequireAuth = ({ children }: { children: JSX.Element }) => {
		const { user } = useAuth();
		const location = useLocation();
		const navigate = useNavigate();
		useEffectCustom(() => {
			if (user) {
				if (location.pathname === '/') {
					navigate(AppRoutes.DASHBOARD);
				}
			}
		}, []);

		if (!user) {
			return <Navigate to="/login" state={{ from: location }} replace />;
		}

		return children;
	};
	return (
		<BrowserRouter>
			<Routes>
				<Route path={AppRoutes.LOGIN} element={<Login />} />
				<Route path={AppRoutes.FORGOT_PASSWORD} element={<ForgotPassword />} />
				<Route path={AppRoutes.CHANGE_PASSWORD_LINK} element={<ResetPassword />} />
				<Route path={AppRoutes.CHANGE_EMAIL_LINK} element={<ChangeEmail />} />
				<Route
					path={AppRoutes.MAIN}
					element={(
						<RequireAuth>
							<Main />
						</RequireAuth>
					)}
				>

					<Route path={AppRoutes.DASHBOARD} element={<Dashboard />} />
					<Route path={AppRoutes.LIST_USERS} element={<ListUsers />} />
					<Route path={AppRoutes.NEW_USER} element={<CreateUser />} />
					<Route path={AppRoutes.EDIT_ACTIVE_USER} element={<CreateUser />} />
					<Route path={AppRoutes.EDIT_USER} element={<CreateUser />} />
					<Route path={AppRoutes.PROMOTE_ADM} element={<PromoteToAdm />} />
					<Route path={AppRoutes.SEND_MESSAGE} element={<SendMessage />} />
					<Route path={AppRoutes.ADMINISTRATORS} element={<Administrators />} />
					<Route path={AppRoutes.CARDS} element={<ListCards />} />
					<Route path={AppRoutes.CREATE_CARD} element={<CreateCard />} />
					<Route path={AppRoutes.EDIT_CARD} element={<CreateCard />} />
					<Route path={AppRoutes.ISSUE_CARD} element={<IssueCard />} />
					<Route path={AppRoutes.LIST_COUPONS} element={<CouponList />} />
					<Route path={AppRoutes.EDIT_COUPON} element={<CreateCoupon />} />
					<Route path={AppRoutes.ORGANIZATIONS} element={<ListOrganizations />} />
					<Route path={AppRoutes.EDIT_ORGANIZATION} element={<CreateOrganization />} />
					<Route path={AppRoutes.CREATE_ORGANIZATION} element={<CreateOrganization />} />
					<Route path={AppRoutes.CREATE_PARTNER} element={<CreatePartner />} />
					<Route path={AppRoutes.EDIT_PARTNER} element={<CreatePartner />} />
					<Route path={AppRoutes.PARTNERS} element={<ListPartners />} />
					<Route path={AppRoutes.CHAIN_STORES} element={<ListChainStore />} />
					<Route path={AppRoutes.EDIT_CHAIN_STORE} element={<CreateChainStore />} />
					<Route path={AppRoutes.CREATE_CHAIN_STORE} element={<CreateChainStore />} />
					<Route path={AppRoutes.NEW_COUPON} element={<CreateCoupon />} />
					<Route path={AppRoutes.COUPON_REQUESTS} element={<RequestedCoupons />} />
					<Route path={AppRoutes.USERS_REPORT} element={<UsersReport />} />
					<Route path={AppRoutes.COUPONS_REPORT} element={<CouponsReport />} />
					<Route path={AppRoutes.TRANSACTION_REPORT} element={<TransactionReport />} />
					<Route path={AppRoutes.COST_CENTERS_ORGANIZATION} element={<ListCostCenter />} />
					<Route path={AppRoutes.COST_CENTERS_PARTNER} element={<ListCostCenter />} />
					<Route path={AppRoutes.CREATE_COST_CENTER_ORGANIZATION} element={<CreateCostCenter />} />
					<Route path={AppRoutes.CREATE_COST_CENTER_PARTNER} element={<CreateCostCenter />} />
					<Route path={AppRoutes.EDIT_COST_CENTER_PARTNER} element={<CreateCostCenter />} />
					<Route path={AppRoutes.EDIT_COST_CENTER_ORGANIZATION} element={<CreateCostCenter />} />
					<Route path={AppRoutes.LIST_TRANSACTIONS} element={<Transactions />} />
					<Route path={AppRoutes.TERMS} element={<TermsList />} />
					<Route path={AppRoutes.EDIT_TERM} element={<CreateTerm />} />
					<Route path={AppRoutes.NEW_TERM} element={<CreateTerm />} />
				</Route>
				<Route path="*" element={<PageNotFound />} />
			</Routes>
		</BrowserRouter>
	);
};

export default AppRouter;
