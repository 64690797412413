import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import i18next from 'i18next';
import Endpoint from './endpoints.api';
import LocalStorageWrapper, { LocalStorageKeys } from 'utils/storage.utils';
import { IUser } from '../models';

const BASE_URL = process.env.REACT_APP_BASE_URL ?? Endpoint.BASE_URL;
const UNAUTORIZED = 401;
const STATUS_SUCCESS = 200;

const axiosConfig = {
	baseURL: BASE_URL,
};

export const $axios = axios.create(axiosConfig);

const request = async (config: AxiosRequestConfig) => {
	const user = LocalStorageWrapper.get<IUser>(LocalStorageKeys.USER);
	const lang = LocalStorageWrapper.get<string>(LocalStorageKeys.LOCALE)?.substring(0, 2);
	const refreshToken = LocalStorageWrapper.get<string>(LocalStorageKeys.REFRESH_TOKEN);
	const bearerToken = user?.authenticationToken;

	config.headers = { // eslint-disable-line no-param-reassign
		...config.headers,
		'Access-Control-Allow-Origin': BASE_URL,
		Lang: lang ?? i18next.language.substring(0, 2),
		Accept: 'application/json',
		...(bearerToken
			? {
				Authorization: `Bearer ${bearerToken}`,
			}
			: {}),
	};

	/** CHECK IF THE USER IS UNAUTHORIZED AND REFRESHE THE TOKEN */
	/** AXIOS RESPONSE INTERCEPTOR */
	$axios.interceptors.response.use(
		(response) => response,
		async (error: AxiosError) => {
			const originalRequest = error.config;
			if (error?.response?.status === UNAUTORIZED) {
				try {
					const resp = await $axios({
						url: Endpoint.REFRESH_TOKEN,
						method: 'POST',
						data: {
							refreshToken,
						},
						headers: {
							...config.headers,
							'Access-Control-Allow-Origin': BASE_URL,
							Lang: lang ?? i18next.language.substring(0, 2),
							Accept: 'application/json',
							Authorization: `Bearer ${bearerToken}`,
						},
					});
					if (resp.status !== STATUS_SUCCESS) {
						LocalStorageWrapper.remove(LocalStorageKeys.USER);
						LocalStorageWrapper.remove(LocalStorageKeys.REFRESH_TOKEN);
						window.location.reload();
						return null;
					}
					/**
						If the token is refreshed successfully then retry the original request with the new
						authentication token
				 	*/
					if (resp.status === 200) {
						LocalStorageWrapper.set(LocalStorageKeys.USER, resp.data.data);
						const returnValue = await $axios({
							...originalRequest,
							headers: {
								...originalRequest?.headers,
								Authorization: `Bearer ${resp.data.data.authenticationToken}`,
							},
						});
						return returnValue;
					}
				} catch (err) {
					LocalStorageWrapper.remove(LocalStorageKeys.USER);
					LocalStorageWrapper.remove(LocalStorageKeys.REFRESH_TOKEN);
					window.location.reload();
				}
			}

			throw error;
		},
	);

	const returnValue = await $axios({ ...config });
	return returnValue;
};

export const reqGet = async (config: AxiosRequestConfig) => request({
	method: 'GET',
	...config,
});

export const reqPost = async (config: AxiosRequestConfig) => request({
	method: 'POST',
	...config,
});

export const reqDelete = async (config: AxiosRequestConfig) => request({
	method: 'DELETE',
	...config,
});

export default request;
