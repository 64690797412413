/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ListPartnersPage from './ListPartnersPage';
import { useFeedback, useTitleBar } from 'providers';
import { useRequest } from 'data/api';
import { changePartnerStatus, getPartnerBarCode, getPartners } from 'data/api/requests';
import { useEffectCustom } from 'components/hooks';
import { AppRoutes } from 'components/routes/app-links.routes';
import { IOrganization, IPaginationTable } from 'data/models';

const ListPartners = () => {
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { addToast } = useFeedback();
	const location = useLocation();
	const [searchParam, setSearchParam] = useSearchParams();

	const partners = useRequest(([params]) => getPartners(params));
	const changeStatus = useRequest(([params]) => changePartnerStatus(params));
	const navigate = useNavigate();

	const [pagination, setPagination] = useState({
		page: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
		size: parseInt(new URLSearchParams(location.search).get('size') || '10', 10),
	});
	const [search, setSearch] = useState(new URLSearchParams(location.search).get('search') || '');

	useEffect(() => {
		setTitle(t('partners'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		const getData = () => {
			const listDataControl = {
				page: pagination.page,
				size: pagination.size,
				search,
			};
			partners.execute(listDataControl);
		};
		getData();
	}, [pagination, search]);

	useEffectCustom(() => {
		const { data } = changeStatus;
		if (data) {
			partners.setData((mPrev) => {
				const index = mPrev?.data.findIndex((el) => el.id === data.id);
				const prev = mPrev;
				if (index !== undefined && prev) {
					prev.data[index] = data;
				}
				return prev;
			});
		}
	}, [changeStatus.data]);

	function base64toBlob(base64Data: string) {
		const sliceSize = 1024;
		const byteCharacters = atob(base64Data);
		const bytesLength = byteCharacters.length;
		const slicesCount = Math.ceil(bytesLength / sliceSize);
		const byteArrays = new Array(slicesCount);

		for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
			const begin = sliceIndex * sliceSize;
			const end = Math.min(begin + sliceSize, bytesLength);

			const bytes = new Array(end - begin);
			for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
				bytes[i] = byteCharacters[offset].charCodeAt(0);
			}
			byteArrays[sliceIndex] = new Uint8Array(bytes);
		}
		return new Blob(byteArrays, { type: 'image/png' });
	}

	const getQRCode = (partnerId?: number) => {
		setGlobalLoading(true);
		getPartnerBarCode(partnerId ?? 0).then(({ data }) => {
			const blob = base64toBlob(data.qrCode);
			if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
				(window.navigator as any).msSaveOrOpenBlob(blob, 'imgBase64.png');
			} else {
				const blobUrl = URL.createObjectURL(blob);
				window.open(blobUrl);
			}
		})
			.catch(({ response }) => addToast({ message: response.data.message, error: true }))
			.finally(() => setGlobalLoading(false));
	};

	const handleListAction = (action: string, item?: IOrganization) => {
		switch (action) {
		case t('new'):
			navigate(AppRoutes.CREATE_PARTNER);
			break;
		case t('details'):
			navigate(AppRoutes.EDIT_PARTNER.formatMap({ id: item?.id }));
			break;
		case t('changeStatus'):
			changeStatus.execute(item?.id);
			break;
		case t('services'):
			navigate(AppRoutes.SERVICES_PARTNERS.formatMap({ partnerId: item?.id }));
			break;
		case t('costCenter'):
			navigate(AppRoutes.COST_CENTERS_PARTNER.formatMap({ partnerId: item?.id }));
			break;
		case t('qrCode'):
			getQRCode(item?.id);
			break;

		default:
			break;
		}
	};

	const setTableSearch = (searchQuery: string) => {
		setSearch(searchQuery);
		searchParam.set('search', searchQuery);
		setSearchParam(searchParam);
	};

	const setTablePagination = (paginationTable: IPaginationTable) => {
		setPagination(paginationTable);
		searchParam.set('page', paginationTable.page.toString());
		searchParam.set('size', paginationTable.size.toString());
		setSearchParam(searchParam);
	};

	return (
		<ListPartnersPage
			partners={partners.data}
			setPagination={setTablePagination}
			setSearch={setTableSearch}
			handleListAction={handleListAction}
		/>
	);
};

export default ListPartners;
