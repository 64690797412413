import React, { FC } from 'react';
import {
	Box, Container, Grid, Link, Typography,
} from '@mui/material';
import {
	Formik, Form, FormikHelpers,
} from 'formik';
import { useTranslation } from 'react-i18next';
import {
	DefaultButton,
	Image,
	Space,
	TextField,
} from 'components/atoms';
import { logo } from 'assets/image';

export interface LoginValues {
   email: string;
   password: string;
}

interface LoginPageProps {
   initialValues: LoginValues;
   submit: (values: LoginValues, formikHelpers: FormikHelpers<LoginValues>) => void;
   validate: (values: LoginValues) => Partial<LoginValues>;
   forgotPassword?: () => void
}

const LoginPage: FC<LoginPageProps> = (props) => {
	const {
		initialValues, submit, validate, forgotPassword,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<Container>
			<Container maxWidth="xs">
				<Space height={5} />

				<Image src={logo} height="100%" width="100%" alt="AJ Energy Logo" />

				<Space height={3} />

				<Formik
					initialValues={initialValues}
					validateOnMount={false}
					validateOnBlur={false}
					validateOnChange={false}
					onSubmit={submit}
					validate={validate}

				>
					{({
						handleChange, values, errors, isSubmitting,
					}) => (
						<Form autoComplete="off">
							<TextField
								name="email"
								id="email"
								type="email"
								label={t('email')}
								placeholder={t('email')}
								aria-label={t('email')}
								value={values.email}
								error={!!errors.email}
								helperText={errors.email}
								onChange={handleChange}
								disabled={isSubmitting}
							/>

							<Space height={2} />

							<TextField
								name="password"
								id="pasword"
								aria-label={t('password')}
								type="password"
								label={t('password')}
								placeholder={t('password')}
								value={values.password}
								error={!!errors.password}
								helperText={errors.password}
								onChange={handleChange}
								disabled={isSubmitting}
							/>

							<Space height={2} />

							<DefaultButton
								id="submit"
								name="submit"
								data-testid="submit"
								title={t('authenticate')}
								aria-label={t('authenticate')}
								type="submit"
								loading={isSubmitting}
								fullWidth
							/>
						</Form>
					)}
				</Formik>
				<Space height={1} />
				<Grid container sx={{ textAlign: 'center' }}>
					<Grid item xs>
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
						<Link component="button" onClick={() => forgotPassword?.call(0)} variant="caption">
							{t('forgotPassword')}
						</Link>
					</Grid>
				</Grid>
				<Space height={2} />

				<Box display="flex" justifyContent="center">
					<Typography variant="caption">
						{process.env.REACT_APP_NAME}
						{' '}
						-
						{' '}
						{t('copyright')}
						{' '}
						©
						{' '}
						{new Date().getFullYear()}
					</Typography>
				</Box>
			</Container>
		</Container>
	);
};

export default LoginPage;
