/* eslint-disable @typescript-eslint/no-explicit-any */
import { FontSizes } from 'utils/constants';
import {
	FormControl, InputLabel, MenuItem, SelectChangeEvent, Select, FormHelperText, useTheme,
} from '@mui/material';
import React from 'react';

interface SelectProps<T> {
	options: T[];
	label: string;
   renderItem: (item: T) => { optionValue: any; optionLabel: string };
   onChange: (value: SelectChangeEvent<string>) => void;
   disabled?: boolean;
   value: any;
   error?: boolean;
   helperText?: string;
   id?: string;
   required?: boolean
}

const DefaultSelect = <T, >(props: SelectProps<T>) => {
	const {
		options, label, renderItem, onChange, disabled, value, error, helperText, id, required,
	} = props;
	const theme = useTheme();

	return (
		<FormControl
			fullWidth
			size="small"
			disabled={disabled}
			error={error}
			required={required}
			sx={{
				'& .MuiOutlinedInput-root': {
					'& fieldset': {
						borderColor: theme.palette.divider,
						transition: 'border-color 0.3s',
					},
				},
				'&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
					borderColor: theme.palette.primary.main,
				},
			}}
		>
			<InputLabel id="demo-simple-select-label">{label}</InputLabel>
			<Select
				labelId="demo-simple-select-label"
				id={id || 'demo-simple-select'}
				value={value}
				label={label}
				onChange={(e) => onChange(e)}
			>
				<MenuItem sx={{ fontSize: FontSizes.MD }} value="">
					<em>--</em>
				</MenuItem>
				{options.map((el, index) => {
					const { optionValue, optionLabel } = renderItem(el);
					return <MenuItem sx={{ fontSize: FontSizes.MD }} value={optionValue} key={`select-item-${index.toString()}`}>{optionLabel}</MenuItem>;
				})}

			</Select>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
};

export default DefaultSelect;
