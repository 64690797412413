/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ICardReq } from 'data/models/card.model';
import { FormikErrors, FormikHelpers } from 'formik';
import CreateCardPage from './CreateCardPage';
import { useFeedback, useTitleBar } from 'providers';
import { useRequest } from 'data/api';
import { INameAndId, IOrganization } from 'data/models';
import { useEffectCustom } from 'components/hooks';
import { putDecimalPoints } from 'utils/masks';
import {
	createUserCard,
	editCard,
	getBillingTypes,
	getCardById,
	getCardCategories,
	getCardTypes,
	getOrganizations,
} from 'data/api/requests';
import { AppRoutes } from 'components/routes/app-links.routes';

const MAIN_CARD = '1';
const LIMITLESS = 3;

const CreateCard = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const { addToast, addDialog } = useFeedback();
	const { id } = useParams();
	const navigate = useNavigate();

	const cardTypes = useRequest(([signal]) => getCardTypes(signal));
	const cardCategories = useRequest(([signal]) => getCardCategories(signal));
	const cardBillingTypes = useRequest(([signal]) => getBillingTypes(signal));
	const organizations = useRequest(([
		params, signal, sort,
	]) => getOrganizations(params, signal, sort));

	const [card, setCard] = useState<ICardReq>({
		name: '',
		description: '',
		cardTypeId: undefined as INameAndId | undefined,
		organizationId: undefined as IOrganization| undefined,
		categoryId: undefined as INameAndId| undefined,
		billingTypeId: undefined as INameAndId| undefined,
		cardLimit: '',
		webhookUri: '',
		webhookHeaders: '',
		cardImage: '',
	});
	const [isEdit, setEdit] = useState(false);

	useEffect(() => {
		if (id) {
			setTitle(`${t('cardDetails')}`);
		} else {
			setEdit(true);
			setTitle(`${t('new')} ${t('card')}`);
		}
	}, [id, setTitle, t]);

	useEffectCustom(() => {
		const abortCtrl = new AbortController();
		const getDropdownData = () => {
			const listControl = {
				page: 1,
				size: 100000,
				search: '',
			};
			cardTypes.execute(abortCtrl.signal);
			cardCategories.execute(abortCtrl.signal);
			cardBillingTypes.execute(abortCtrl.signal);
			organizations.execute(listControl, abortCtrl.signal, true);
			if (id) {
				getCardById(id, abortCtrl.signal).then(({ data }) => {
					setCard({
						name: data.name,
						description: data.description,
						cardImage: data.image,
						cardTypeId: data.type,
						organizationId: data.organization,
						categoryId: data.cardCategory,
						billingTypeId: data.billingType,
						cardLimit: putDecimalPoints(data.limit),
						webhookUri: data.webhookUri,
						webhookHeaders: data.webhookHeaders,

					});
				}).catch(({ response }) => addToast({ message: response.data.title, error: true }));
			}
		};
		getDropdownData();
		return () => abortCtrl.abort();
	}, []);

	const validateForm = (values: ICardReq) => {
		const errors = {} as FormikErrors<ICardReq>;
		if (!values?.cardImage) {
			errors.cardImage = t('emptyField');
		}
		if (!values.name) {
			errors.name = t('emptyField');
		}
		if (!values.description) {
			errors.description = t('emptyField');
		}
		if (values.webhookUri && !values.webhookHeaders) {
			errors.webhookHeaders = t('emptyField');
		}
		if (values.webhookUri) {
			if (!/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g.test(values.webhookUri)) {
				errors.webhookUri = t('validUrl');
			}
		}
		if (!values.cardTypeId) {
			errors.cardTypeId = t('emptyField');
		}
		if (!values.billingTypeId) {
			errors.billingTypeId = t('emptyField');
		}
		if (!values.categoryId?.id) {
			errors.categoryId = t('emptyField');
		}
		if (values?.categoryId?.id !== LIMITLESS) {
			if (!values?.cardLimit) {
				errors.cardLimit = t('emptyField');
			}
		}
		return errors;
	};

	const submitForm = (values: ICardReq, helpers: FormikHelpers<ICardReq>) => {
		if (id) {
			editCard(id, values)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.CARDS);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => helpers.setSubmitting(false));
		} else {
			createUserCard(values)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.CARDS);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => helpers.setSubmitting(false));
		}
	};

	const handleEditClick = () => {
		setTitle(`${t('edit')} ${t('card')}`);
		setEdit(true);
	};

	return 	(
		<CreateCardPage
			categories={cardCategories.data}
			cardTypes={cardTypes.data}
			billingTypes={cardBillingTypes.data}
			organizations={organizations.data?.data}
			card={card}
			onSubmit={submitForm}
			validate={validateForm}
			readOnly={id === MAIN_CARD}
			isEdit={isEdit}
			handleEditClick={handleEditClick}
		/>
	);
};

export default CreateCard;
