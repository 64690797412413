import { FontSizes } from 'utils/constants';
import { Theme } from '@mui/material';

export const statusStyles = (theme: Theme, color?: string) => ({
	backgroundColor: color ?? theme.palette.background.default,
	color: 'white',
	paddingLeft: 6,
	paddingRight: 6,
	paddingTop: 4,
	paddingBottom: 4,
	fontSize: FontSizes.SM,
	borderRadius: '0.25rem',
	fontWeight: 'bold',
});
