import React, {
	createContext, useContext, useMemo, useState,
} from 'react';
import LocalStorageWrapper, { LocalStorageKeys } from 'utils/storage.utils';
import { useEffectCustom } from 'components/hooks';
import { getFingerprint } from 'utils/general.utils';

interface SettingContextProps {
	updateUIMode: () => void;
	uniqueHash: string
}

interface Context_Props {
	updateUIMode: () => void;
	children: React.ReactNode
}

export const SettingContext = createContext<SettingContextProps>({} as SettingContextProps);

export const SettingProvider: React.FC<Context_Props> = ({ children, updateUIMode }) => {
	const [uniqueHash, setUniqueHash] = useState<string>('');
	useEffectCustom(() => {
		const mHash: string|null = LocalStorageWrapper.get(LocalStorageKeys.HASH);
		if (mHash) {
			setUniqueHash(mHash);
		} else {
			const genHash = getFingerprint();
			setUniqueHash(genHash.toString());
			LocalStorageWrapper.set(LocalStorageKeys.HASH, genHash);
		}
	}, []);
	const value = useMemo(() => ({ updateUIMode, uniqueHash }), [updateUIMode, uniqueHash]);
	return <SettingContext.Provider value={value}>{children}</SettingContext.Provider>;
};

export default function useSettingContext() {
	return useContext(SettingContext);
}
