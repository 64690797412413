/* eslint-disable no-param-reassign */
import React from 'react';
import Description from '../description/Description';
import { placeholderImage } from 'assets/image';
import { FontSizes } from 'utils/constants';

export interface ImageProps {
   src?: string;
   height?: number | string;
   width?: number | string;
   alt: string;
   fit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
   style?: React.CSSProperties;
   title?: string,
   description?: string
}

const CardImage = (props: ImageProps) => {
	const {
		src, height, width, alt, fit, style, title, description,
	} = props;
	return (
		<div style={{
			height,
			width,
			position: 'relative',
			textAlign: 'center',
			color: 'white',
		}}
		>
			<img
				src={src || placeholderImage}
				height={height}
				width={width}
				alt={alt}
				style={{ objectFit: fit ?? 'fill', ...style, opacity: 1 }}
				onError={({ currentTarget }) => {
					currentTarget.onerror = null;
					currentTarget.src = placeholderImage;
				}}
			/>
			{title && (
				<Description
					text={title}
					fontWeight="500"
					style={{
						position: 'absolute',
						top: '8px',
						left: '16px',
					}}
				/>
			)}
			{description && (
				<Description
					text={description}
					fontSize={FontSizes.XS}
					fontWeight="500"
					style={{
						position: 'absolute',
						top: '25px',
						left: '16px',
					}}
				/>
			)}
		</div>
	);
};

export default CardImage;
