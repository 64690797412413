/* eslint-disable no-useless-escape */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Card as MuiCard,
	CardActions,
	CardContent,
	CardHeader,
	Avatar,
	Stack,
	useTheme,
} from '@mui/material';
import {
	Autocomplete,
	DefaultButton,
	Space,
	TableItem,
	TextField,
} from 'components/atoms';
import {
	ICard,
	ICountry,
	IIssueCardState,
} from 'data/models';
import { profileImage } from 'assets/image';
import { IUserByPhone } from 'data/models/user.model';
import {
	Form,
	Formik,
	FormikErrors,
	FormikHelpers,
} from 'formik';
import { useMask } from '@react-input/mask';

interface Props {
	user?: IUserByPhone;
	card?: ICard;
	countries?: ICountry[];
	onChange: (key: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	onIssueClick: (values: IIssueCardState, helpers: FormikHelpers<IIssueCardState>) => void;
	issueCardLoading: boolean;
	issueFieldError: string;
	issueCardFields: IIssueCardState;
	validate: (values: IIssueCardState) => FormikErrors<IIssueCardState>
}

const LIMITLESS_CARD = 4;

const IssueCardForm: FC<Props> = (props) => {
	const {
		user,
		card,
		countries,
		onIssueClick,
		issueCardFields,
		validate,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const carRegistrationInputRef = useMask({ mask: '__-__-__', replacement: { _: /[A-Za-z0-9]/ } });
	const postCodeRef = useMask({ mask: '____-___', replacement: { _: /\d/ } });
	const nifInputRef = useMask({ mask: '________-_', replacement: { _: /\d/ } });
	return (
		<MuiCard sx={{ maxWidth: '100%', border: `1px ridge ${theme.palette.divider}` }} elevation={0}>
			<CardHeader
				avatar={(
					<Avatar src={user?.profileImage || profileImage} sx={{ bgcolor: 'primary.main' }} aria-label="recipe">
						{user?.name && user.name[0]}
					</Avatar>
				)}
				title={user?.name}
				subheader={(<TableItem title={`${t('nif')}: ${user?.taxId}`} subTitle={`${t('email')}: ${user?.email || ''}`} />)}
			/>
			<Formik
				initialValues={issueCardFields}
				onSubmit={onIssueClick}
				validate={validate}
				validateOnBlur={false}
				validateOnMount={false}
				enableReinitialize
			>
				{({
					values, setFieldValue, errors, isSubmitting, touched, handleChange,
				}) => (
					<Form autoComplete="off">
						<CardContent>
							{card && card?.type.id !== LIMITLESS_CARD && (
								<TextField
									name="externalId"
									value={values.externalId}
									error={!!errors.externalId}
									helperText={errors.externalId}
									label={t('externalId')}
									onChange={handleChange}
								/>
							)}
							<Space height={2} />
							<TextField
								label={t('cardName')}
								name="cardName"
								disabled={isSubmitting}
								error={!!errors.cardName}
								helperText={errors.cardName}
								value={values.cardName}
								onChange={handleChange}
							/>
							{card && card?.type.id === LIMITLESS_CARD && (
								<>
									<Space height={2} />
									<TextField
										label={t('subject_name')} // Person name
										name="userName"
										disabled={isSubmitting}
										error={!!errors.userName}
										helperText={errors.userName}
										value={values.userName}
										onChange={handleChange}
									/>
									<Space height={2} />
									<TextField
										label={t('nif')}
										name="taxId"
										inputRef={nifInputRef}
										disabled={isSubmitting}
										error={!!errors.taxId}
										helperText={errors.taxId}
										value={values.taxId}
										onChange={handleChange}
									/>
									<Space height={2} />
									<Autocomplete
										label={t('place')}
										placeholder=""
										disabled={isSubmitting}
										options={countries || []}
										error={Boolean(touched.countryId && errors.countryId)}
										helperText={touched.countryId && errors.countryId}
										getOptionLabel={(item) => item.name || ''}
										renderOption={(AutoCompleteprops, item) => (
											<li {...AutoCompleteprops}>{item.name}</li>
										)}
										defaultValue={values.countryId}
										isOptionEqualToValue={(item, option) => item.id === option.id}
										onChange={(_, item) => {
											setFieldValue('countryId', item);
										}}
									/>
									<Space height={2} />
									<TextField
										label={t('neighborhood')}
										name="neighborhood"
										disabled={isSubmitting}
										error={Boolean(touched.neighborhood && errors.neighborhood)}
										helperText={touched.neighborhood && errors.neighborhood}
										value={values.neighborhood}
										onChange={handleChange}
									/>
									<Space height={2} />
									<TextField
										label={t('address')}
										name="address"
										disabled={isSubmitting}
										error={Boolean(touched.address && errors.address)}
										helperText={touched.address && errors.address}
										value={values.address}
										onChange={handleChange}
									/>
									<Space height={2} />
									<TextField
										inputRef={postCodeRef}
										label={t('postalCode')}
										name="postCode"
										disabled={isSubmitting}
										error={Boolean(touched.postCode && errors.postCode)}
										helperText={touched.postCode && errors.postCode}
										value={values.postCode}
										onChange={handleChange}
									/>
									<Space height={2} />
									<TextField
										label={t('carPlate')}
										name="carPlates"
										inputProps={{ style: { textTransform: 'uppercase' } }}
										inputRef={carRegistrationInputRef}
										disabled={isSubmitting}
										error={Boolean(touched.carPlates && errors.carPlates)}
										helperText={touched.carPlates && errors.carPlates}
										value={values.carPlates}
										onChange={(event) => handleChange(event)}
									/>
								</>
							)}
						</CardContent>
						<CardActions>
							<Stack width="100%" direction="row" justifyContent="center">
								<DefaultButton
									title={t('issue')}
									type="submit"
									loading={isSubmitting}
								/>
							</Stack>
						</CardActions>
					</Form>
				)}
			</Formik>
		</MuiCard>
	);
};

export default IssueCardForm;
