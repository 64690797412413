import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PromoteToAdmPage, { ADMIN_ACTION } from './PromoteToAdmPage';
import { useFeedback } from 'providers';
import { useRequest } from 'data/api';
import { getUserById } from 'data/api/requests/user.service';
import { useEffectCustom } from 'components/hooks';
import { IUser } from 'data/models';
import { promoteToAdm } from 'data/api/requests';
import { AppRoutes } from 'components/routes/app-links.routes';

const PromoteToAdm = () => {
	const { id } = useParams<{ id: string }>();
	const { addToast } = useFeedback();

	const getUser = useRequest(([params]) => getUserById(params));
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);

	useEffectCustom(() => {
		if (id) {
			getUser.execute(id);
		}
	}, [id]);

	const getAdmin = (): IUser[] | [] | undefined => {
		if (getUser.data) {
			return [getUser.data];
		}
		return [];
	};

	const promoteToAdmin = () => {
		setLoading(true);
		promoteToAdm(id)
			.then((res) => {
				addToast({ message: res.message, error: false });
				navigate(AppRoutes.ADMINISTRATORS);
			})
			.catch(({ response }) => addToast({ message: response.data.message, error: true }))
			.finally(() => setLoading(false));
	};

	const onButtonClick = (action: string) => {
		switch (action) {
		case ADMIN_ACTION.YES:
			promoteToAdmin();
			break;
		case ADMIN_ACTION.NO:
			navigate(AppRoutes.LIST_USERS);
			break;
		default:
			break;
		}
	};

	return (
		<PromoteToAdmPage
			user={getAdmin()}
			handleClick={onButtonClick}
			loading={loading}
		/>
	);
};

export default PromoteToAdm;
