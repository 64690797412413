import { Description } from 'components/atoms';
import { Stack, useTheme } from '@mui/material';
import React from 'react';
import { FontSizes } from 'utils/constants';
import { ICardUserDetail } from 'data/models/user.model';
import { useTranslation } from 'react-i18next';

interface Props {
	details: ICardUserDetail | null
}

const CardUserDetails: React.FC<Props> = (props) => {
	const { details } = props;
	const { t } = useTranslation('translations');
	const theme = useTheme();
	return (
		<Stack direction="column" sx={{ pl: 1 }}>
			<Description text={details?.name || ''} fontWeight="500" />
			{details?.taxId && (
				<Stack direction="row">
					<Description fontSize={FontSizes.SM} fontWeight="500" text={`${t('nif')}: `} />
					<Description fontSize={FontSizes.SM} text={details?.taxId || ''} />
				</Stack>
			)}
			<Stack direction="row">
				<Description fontSize={FontSizes.SM} text={details?.taxIdCountry?.name || ''} />
			</Stack>
			<Stack direction="row">
				<Description fontSize={FontSizes.SM} text={details?.address || ''} />
			</Stack>
			<Stack direction="row">
				<Description fontSize={FontSizes.SM} text={`${details?.neighborhood || ''} ${details?.postalCode || ''}`} />
			</Stack>
			{details?.carPlate && (
				<Stack
					direction="row"
					justifyContent="center"
					sx={{ border: `1px solid ${theme.palette.primary.main}`, borderRadius: '3px' }}
				>
					<Description fontWeight="bold" fontSize={FontSizes.SM} text={details?.carPlate || ''} />
				</Stack>
			)}
		</Stack>
	);
};

export default CardUserDetails;
