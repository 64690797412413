import {
	ApiResponse,
	IListDataControl,
	IPagination,
	ITransaction,
	ITransactionReport,
	TransactionReportReq,
} from 'data/models';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';
import { encodeDate } from 'utils/helpers';

export const getTransactions = async (props: IListDataControl):
    Promise<ApiResponse<IPagination<ITransaction[]>>> => {
	const { page, size, search } = props;

	const resp = await reqGet({
		url: Endpoint.LIST_TRANSACTIONS.format(page, size, search),
	});
	const data = resp.data as ApiResponse<IPagination<ITransaction[]>>;
	data.statusCode = resp?.status;
	return data;
};

export const confirmTransaction = async (id?: number): Promise<ApiResponse<ITransaction>> => {
	const resp = await reqPost({
		url: Endpoint.APPROVE_TRANSACTION.format(id),
	});
	const data = resp.data as ApiResponse<ITransaction>;
	data.statusCode = resp?.status;
	return data;
};

export const cancelTransaction = async (id?: number): Promise<ApiResponse<ITransaction>> => {
	const resp = await reqPost({
		url: Endpoint.CANCEL_TRANSACTION.format(id),
	});
	const data = resp.data as ApiResponse<ITransaction>;
	data.statusCode = resp?.status;
	return data;
};

export const getTransactionReport = async (params: TransactionReportReq):
Promise<ApiResponse<ITransactionReport[]>> => {
	const resp = await reqGet({
		url: Endpoint.TRANSACTION_REPORT.format(
			encodeDate(params.startDate),
			encodeDate(params.endDate),
			params.cardId.id,
			params.userId?.id,
		),
	});
	const data = resp.data as ApiResponse<ITransactionReport[]>;
	data.statusCode = resp.status;
	return data;
};
