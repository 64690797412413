import { ICardUser, IIssueCardState } from 'data/models/card.model';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import IssueCardPage from './IssueCardPage';
import { useFeedback, useTitleBar } from 'providers';
import { useRequest } from 'data/api';
import { getUserByPhone } from 'data/api/requests/user.service';
import {
	changeUserCardStatus,
	getCardById,
	getCardUsers,
	getCountries,
	issueCardToUser,
} from 'data/api/requests';
import { useEffectCustom } from 'components/hooks';
import { CARD_STATUSES } from 'utils/constants';
import { IPaginationTable } from 'data/models';
import IssueCardForm from './IssueCardForm';
import { IUserByPhone } from 'data/models/user.model';
import { FormikErrors, FormikHelpers } from 'formik';
import { taxIdString } from 'utils/masks';

const PHONE_LENGTH = 11;
const EXTERNAL_CARD = 2;

const IssueCard = () => {
	const { setTitle } = useTitleBar();
	const { t } = useTranslation('translations');
	const { addToast } = useFeedback();
	const location = useLocation();
	const [searchParam, setSearchParam] = useSearchParams();

	const { id } = useParams();

	const [user, setUser] = useState<IUserByPhone>();
	const [userGetError, setUserGetError] = useState('');
	const card = useRequest(([params, signal]) => getCardById(params, signal));
	const cardUsers = useRequest(([param1, param2, signal]) => getCardUsers(param1, param2, signal));
	const changeStatus = useRequest(([param1, param2]) => changeUserCardStatus(param1, param2));
	const countries = useRequest(() => getCountries());
	const [emptyPhoneError, setEmptyPhoneError] = useState('');

	const [phone, setPhone] = useState('');
	const [pagination, setPagination] = useState({
		page: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
		size: parseInt(new URLSearchParams(location.search).get('size') || '10', 10),
	});
	const [search, setSearch] = useState(new URLSearchParams(location.search).get('search') || '');
	const [issueCardFields, setIssueCardFields] = useState<IIssueCardState>({ externalId: '' } as IIssueCardState);
	const [issueFieldError, setIssueFieldError] = useState('');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setTitle(`${t('card')} - ${t('issue')}`);
	}, [setTitle, t]);

	useEffectCustom(() => {
		const abortCtrl = new AbortController();
		const getData = () => {
			const listDataControl = {
				page: pagination.page,
				size: pagination.size,
				search,
			};
			cardUsers.execute(id, listDataControl, abortCtrl.signal);
			countries.execute();
		};
		getData();
		return () => abortCtrl.abort();
	}, [pagination.page, pagination.size, search]);

	useEffectCustom(() => {
		const abortCtrl = new AbortController();
		const getData = () => {
			card.execute(id, abortCtrl.signal);
		};
		getData();
		return () => abortCtrl.abort();
	}, [id]);

	useEffectCustom(() => {
		const changeStatusLocally = () => {
			const { data } = changeStatus;
			if (data) {
				cardUsers.setData((prev) => {
					const mPrev = prev;
					const index = mPrev?.data.findIndex((el) => el.id === data?.id);
					if (index !== undefined && mPrev) {
						mPrev.data[index] = data;
					}
					return mPrev;
				});
			}
		};
		changeStatusLocally();
	}, [changeStatus.data]);

	const getUser = () => {
		if (!phone) {
			return setEmptyPhoneError(t('emptyField'));
		}
		if (phone.length > 0 && phone.length < PHONE_LENGTH) {
			return setEmptyPhoneError(t('phoneError'));
		}
		setEmptyPhoneError('');
		getUserByPhone(phone).then((res) => {
			setUser(res.data);
			setIssueCardFields({
				...issueCardFields,
				userName: res.data?.name,
				cardName: card.data?.name,
				taxId: taxIdString(res.data.taxId),
				countryId: res.data?.taxCountry,
				neighborhood: res.data?.neighborhood,
				address: res.data?.address,
				postCode: res.data?.postalCode,
				carPlates: '',
			});
		}).catch((err) => {
			setUserGetError(err.response.data.message);
		});
		return null;
	};

	const changeCardStatus = (cardUser: ICardUser) => {
		switch (cardUser.status.id) {
		case CARD_STATUSES.ACTIVATED:
			changeStatus.execute(cardUser.id, CARD_STATUSES.BLOCKED_BY_COMPANY);
			break;
		case CARD_STATUSES.BLOCKED_BY_COMPANY:
			changeStatus.execute(cardUser.id, CARD_STATUSES.ACTIVATED);
			break;
		default:
			break;
		}
	};

	const handleListItemAction = (action: string, cardUser: ICardUser) => {
		switch (action) {
		case t('changeStatus'):
			changeCardStatus(cardUser);
			break;
		default:
			break;
		}
	};

	const handleChange = (
		key: string,
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		setIssueCardFields({ ...issueCardFields, [key]: event.target.value });
		setIssueFieldError('');
	};

	const issueCard = (values: IIssueCardState, helpers: FormikHelpers<IIssueCardState>) => {
		const cardIssueReq = {
			cardId: id,
			userId: user?.id,
			cardTypeId: card.data?.type.id,
			...values,
		};
		setLoading(true);
		issueCardToUser(cardIssueReq)
			.then((res) => {
				addToast({ message: res.message, error: false });
				cardUsers.setData((prev) => {
					const mPrev = prev;
					if (mPrev) {
						mPrev.data.push(res.data);
					}
					return mPrev;
				});
				setUser(undefined);
				setPhone('');
				setIssueCardFields({ externalId: '' } as IIssueCardState);
			})
			.catch(({ response }) => {
				addToast({ message: response.data.message, error: true });
			})
			.finally(() => helpers.setSubmitting(false));
	};

	const setTableSearch = (searchQuery: string) => {
		setSearch(searchQuery);
		searchParam.set('search', searchQuery);
		setSearchParam(searchParam);
	};

	const setTablePagination = (paginationTable: IPaginationTable) => {
		setPagination(paginationTable);
		searchParam.set('page', paginationTable.page.toString());
		searchParam.set('size', paginationTable.size.toString());
		setSearchParam(searchParam);
	};

	const onPhoneChange = (value: string) => {
		setPhone(value);
		setEmptyPhoneError('');
		setUserGetError('');
	};

	const validateFields = (values: IIssueCardState) => {
		const errors = {} as FormikErrors<IIssueCardState>;
		if (card.data?.type.id === EXTERNAL_CARD) {
			if (!values.externalId) {
				errors.externalId = t('emptyField');
			}
			if (!values.cardName) {
				errors.cardName = t('emptyField');
			}
		} else {
			if (!values.cardName) {
				errors.cardName = t('emptyField');
			}
			if (!values.userName) {
				errors.userName = t('emptyField');
			}
			if (values.taxId) {
				if (!values.countryId || Object.keys(values.countryId).length === 0) {
					errors.countryId = t('emptyField');
				}
			}
			if (values.countryId && Object.keys(values.countryId).length !== 0) {
				if (!values.taxId) {
					errors.taxId = t('emptyField');
				}
				if (values.taxId && values.taxId?.length < 10) {
					errors.taxId = t('invalidNif');
				}
			}
		}
		return errors;
	};

	return (
		<IssueCardPage
			phone={phone}
			setPhone={onPhoneChange}
			onSearchPress={getUser}
			user={user}
			error={userGetError || emptyPhoneError}
			card={card.data}
			cardUsers={cardUsers.data}
			setPagination={setTablePagination}
			setSearch={setTableSearch}
			listItemAction={handleListItemAction}
			userCardIssueComponent={(
				<IssueCardForm
					card={card.data}
					issueFieldError={issueFieldError}
					onChange={handleChange}
					onIssueClick={issueCard}
					issueCardLoading={loading}
					user={user}
					countries={countries.data}
					issueCardFields={issueCardFields}
					validate={validateFields}
				/>
			)}
		/>
	);
};

export default IssueCard;
