import { Description } from 'components/atoms';
import { IUser } from 'data/models';
import { FontSizes } from 'utils/constants';
import { Avatar, Box, Stack } from '@mui/material';
import React from 'react';

interface UserTableItemProps{
	user: IUser;
	name?: string;
}

const UserTableItem: React.FC<UserTableItemProps> = (props) => {
	const { user, name } = props;
	return (
		<Stack direction="row">
			<Avatar src={user.profileImage} />
			<Stack direction="column" sx={{ pl: 1 }}>
				<Description text={user.name} fontWeight="500" />
				<Stack direction="row">
					<Description fontSize={FontSizes.SM} fontWeight="500" text="Tel: " />
					<Description fontSize={FontSizes.SM} text={user.phone} />
				</Stack>
				<Stack direction="row">
					<Description fontSize={FontSizes.SM} fontWeight="500" text="NIF: " />
					<Description fontSize={FontSizes.SM} text={user.taxId} />
				</Stack>
				{name && (
					<Box sx={{ textWrap: 'wrap' }}>
						{name}
					</Box>
				)}
			</Stack>
		</Stack>
	);
};

export default UserTableItem;
