import { PaperContainer } from 'components/molecules';
import { IOrganization, IUser } from 'data/models';
import {
	Chip,
	Divider, Grid, Stack, useTheme,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	DefaultButton,
	Description,
	Space, TextField,
} from 'components/atoms';

interface CreateCostCenterProps {
	organization?: IOrganization;
	handleChange: (key: string, value: string)=> void;
	values: {name: string, email: string};
	errors: {name: string, email: string};
	submit: () => void;
	loading: boolean;
	edit?: boolean;
	handlePartnerUserClick?: (action: string, email?: string) => void;
	users?: IUser[];
	addMemberLoading: boolean
}

const CreateCostCenterPage:React.FC<CreateCostCenterProps> = (props) => {
	const {
		organization,
		handleChange,
		values,
		errors,
		submit,
		loading,
		edit,
		handlePartnerUserClick,
		users,
		addMemberLoading,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');
	return (
		<Grid
			sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}
			container
			spacing={3}
		>
			<Grid item xs={12} md={12} />
			<Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
				<PaperContainer>
					<Space height={2} />
					<TextField label={t('organization')} disabled value={organization?.fantasyName || ''} />
					<Space height={2} />
					<TextField
						label={t('name')}
						disabled={loading}
						onChange={(event) => handleChange('name', event.target.value)}
						value={values.name}
						error={!!errors.name}
						helperText={errors.name}
					/>
					<Space height={2} />
					{edit && (
						<div>
							<Divider />
							<Space height={2} />
							<Description text={t('addMembers')} />
							<Space height={1} />
							<Stack direction="row">
								<TextField
									label={t('email')}
									value={values.email}
									error={!!errors.email}
									helperText={errors.email}
									onChange={(event) => handleChange('email', event.target.value)}
								/>
								<DefaultButton title={t('add')} onClick={() => handlePartnerUserClick?.call(0, t('add'))} loading={addMemberLoading} />
							</Stack>
							<Space height={2} />
							{users?.map((el) => (
								<div key={`user-tag-chip-${el.id}`}>
									<Chip
										label={(
											<>
												<div>{el.name}</div>
												<div>{el.email}</div>
											</>
										)}
										onDelete={() => { handlePartnerUserClick?.call(0, t('remove'), el.email); }}
										color="secondary"
										sx={{
											mr: 1, mt: 1, pt: 3, pb: 3,
										}}
									/>
									<Space height={2} />
								</div>
							))}
						</div>
					)}
					<DefaultButton title={t('save')} onClick={submit} loading={loading} />
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default CreateCostCenterPage;
