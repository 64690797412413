/* eslint-disable @typescript-eslint/no-explicit-any */
import * as XLSX from 'xlsx';

export const exportUserExcel = (fileName: string, rows: any, header: string[]) => {
	const worksheet = XLSX.utils.json_to_sheet(rows);
	/** WIDTH OF COLUMNS */
	worksheet['!cols'] = [
		{ wch: 15 },
		{ wch: 15 },
		{ wch: 20 },
		{ wch: 30 },
		{ wch: 12 },
		{ wch: 25 },
		{ wch: 25 },
		{ wch: 25 },
		{ wch: 15 },
		{ wch: 25 },
	];
	const workbook = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(workbook, worksheet, 'dados');
	XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: 'A1' });
	/* create an XLSX file and try to save  */
	XLSX.writeFile(workbook, fileName);
};
