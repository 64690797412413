import {
	Box, Grid, useTheme,
} from '@mui/material';
import {
	Formik, Form, FormikHelpers, FormikErrors,
} from 'formik';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { INameAndId } from 'data/models/misc.model';
import { ICreateTermsReq } from 'data/models';
import { PaperContainer } from 'components/molecules';
import { DefaultButton, DefaultSelect, TextField } from 'components/atoms';

export interface CreateTermsPageProps {
   initialValues: ICreateTermsReq;
   onCreateTerm: (values: ICreateTermsReq, formikHelpers: FormikHelpers<ICreateTermsReq>) => void;
   validate: (values: ICreateTermsReq) => FormikErrors<ICreateTermsReq>;
   categories?: INameAndId[]
}

const CreateArticlePage = (props: CreateTermsPageProps) => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const {
		initialValues, validate, onCreateTerm, categories,
	} = props;
	return (
		<Box>
			<Grid container sx={{ padding: theme.spacing(3) }} spacing={theme.spacing(3)}>
				<Grid item xs={12} md={6} lg={4}>
					<PaperContainer>
						<Formik
							initialValues={initialValues}
							enableReinitialize
							validateOnMount={false}
							validateOnBlur={false}
							validateOnChange={false}
							validate={validate}
							onSubmit={onCreateTerm}
						>
							{({
								handleChange, values, setFieldValue, errors, isSubmitting,
							}) => (
								<Form autoComplete="off">
									<Grid container sx={{ padding: theme.spacing(2) }} spacing={3}>
										<Grid item xs={12}>
											<TextField
												name="link"
												id="maxUsage"
												label={t('link')}
												value={values.link}
												onChange={handleChange}
												error={!!errors.link}
												helperText={errors.link}
												disabled={isSubmitting}
											/>
										</Grid>
										<Grid item xs={12}>
											<DefaultSelect
												options={categories || []}
												label={t('category')}
												value={values.categoryId}
												error={!!errors.categoryId}
												helperText={errors.categoryId}
												renderItem={(item) => ({ optionLabel: item.name, optionValue: item.id })}
												onChange={(event) => setFieldValue('categoryId', event.target.value)}
												disabled={isSubmitting}
											/>
										</Grid>
										<Grid item xs={3}>
											<DefaultButton title={t('save')} type="submit" loading={isSubmitting} />
										</Grid>
									</Grid>
								</Form>
							)}
						</Formik>
					</PaperContainer>
				</Grid>
			</Grid>
		</Box>
	);
};

export default CreateArticlePage;
