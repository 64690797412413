/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICouponReport, ICouponReportReq } from 'data/models';
import { Summarize } from '@mui/icons-material';
import { Grid, useTheme } from '@mui/material';
import { INameAndId } from 'data/models/misc.model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PaperContainer } from 'components/molecules';
import {
	Autocomplete,
	DatePicker,
	DefaultButton,
	Space,
	StatusLabel,
	TableItem,
} from 'components/atoms';
import { DataTable, TableColumn } from 'components/organisms';
import { formatCurrency, formatPercentage } from 'utils/helpers';

interface CouponReportPage{
	couponReport?: ICouponReport[];
	categories: INameAndId[];
	couponRequest: ICouponReportReq;
	onChange: (key: string, value: Date | INameAndId[]) => void;
	onFilterClick: () => void;
	loading: boolean;
	onExportClick: ()=>void
}

const CouponsReportPage: React.FC<CouponReportPage> = (props) => {
	const {
		couponReport,
		categories,
		couponRequest,
		onChange,
		onFilterClick,
		loading,
		onExportClick,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');
	return (
		<div>
			<Grid sx={{ padding: theme.spacing(3) }} container spacing={3}>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<Grid container spacing={2}>
							<Grid item xs={12} md={3}>
								<DatePicker
									label={t('startDate')}
									value={couponRequest.initialDate}
									disabled={loading}
									onChange={(value) => onChange('initialDate', value)}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<DatePicker
									label={t('endDate')}
									value={couponRequest.finalDate}
									disabled={loading}
									onChange={(value) => onChange('finalDate', value)}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<Autocomplete
									label={t('categories')}
									placeholder=""
									multiple
									disabled={loading}
									options={categories}
									defaultValue={couponRequest.categories}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									renderOption={(inputprops, item) => (
										<li
											{...inputprops}
											key={item.id}
										>
											{item.name}
										</li>
									)}
									getOptionLabel={(item: any) => item.name}
									onChange={(_, value) => onChange('categories', value as INameAndId[])}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<DefaultButton
									title={t('filter')}
									onClick={onFilterClick}
									loading={loading}
								/>
							</Grid>
						</Grid>
					</PaperContainer>
				</Grid>
			</Grid>
			<Grid
				sx={{
					pr: theme.spacing(3),
					pl: theme.spacing(3),
					pb: theme.spacing(3),
				}}
				container
				spacing={3}
			>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<DefaultButton title={t('export')} startIcon={<Summarize />} onClick={onExportClick} />
						<Space height={2} />
						<DataTable data={couponReport || []} search={false}>
							<TableColumn headerText={t('title')} render={(item) => <TableItem title={item.title} />} />
							<TableColumn headerText={t('limit')} render={(item) => item.maxUsage} />
							<TableColumn headerText={t('cost')} render={(item) => item.cost} />
							<TableColumn headerText={t('bonus')} render={(item) => (item.isPercentage ? formatPercentage(item.amount) : formatCurrency(item.amount))} />
							<TableColumn headerText={t('requested')} render={(item) => item.requested} />
							<TableColumn headerText={t('used')} render={(item) => item.used} />
							<TableColumn headerText={t('rejected')} render={(item) => item.requested} />
							<TableColumn headerText={t('category')} render={(item) => item.category} />
							<TableColumn headerText={t('status')} render={(item) => <StatusLabel status={item.status} />} />
						</DataTable>
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default CouponsReportPage;
