import { Grid, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import {
	ICouponRequest,
	IPagination,
	IPaginationTable,
} from 'data/models';
import { PaperContainer } from 'components/molecules';
import { DataTable, TableColumn } from 'components/organisms';
import { DateLabel, Dropdown } from 'components/atoms';

export interface RequiredCouponsListProps {
	data: IPagination<ICouponRequest[]> | undefined;
	onPageChange: (pagination: IPaginationTable) => void;
	onSearch: (query: string) => void;
	handleListAction: (action: string, coupon?: ICouponRequest) => void;
}

const RequiredCouponsListPage = (props: RequiredCouponsListProps) => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const {
		data,
		onPageChange,
		onSearch,
		handleListAction,
	} = props;

	const listActions = [t('undoReq')];

	return (
		<div>
			<Grid
				sx={{ padding: theme.spacing(3) }}
				container
				spacing={3}
			>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<DataTable
							data={data?.data || []}
							onSearching={(query) => onSearch(query)}
							onChange={(page, size) => onPageChange({ page, size })}
							pagination
							total={data?.total}
						>
							<TableColumn headerText="ID" render={(item) => `${item.id}`} />
							<TableColumn headerText={t('code')} render={(item) => `${item.code}`} />
							<TableColumn headerText={t('title')} render={(item) => `${item.coupon?.title}`} />
							<TableColumn headerText={t('user')} render={(item) => `${item.user?.name || ''}`} />
							<TableColumn
								headerText={t('registration')}
								render={(item) => <DateLabel date={item.registration} showTime />}
							/>
							<TableColumn
								headerText={t('')}
								render={(coupon) => (
									<Dropdown
										data={listActions}
										label={t('action')}
										onChange={(action) => {
											handleListAction(action, coupon);
										}}
										renderItem={(item) => ({ optionLabel: item, optionValue: item })}
									/>
								)}
							/>
						</DataTable>
					</PaperContainer>
				</Grid>
				<Grid item xs={12} md={12} />
			</Grid>
		</div>
	);
};

export default RequiredCouponsListPage;
