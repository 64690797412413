import {
	DateLabel,
	DefaultButton,
	Dropdown,
	Image,
	StatusLabel,
} from 'components/atoms';
import { Grid, useTheme } from '@mui/material';
import {
	IChainStore,
	IPagination,
	IPaginationTable,
} from 'data/models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PaperContainer } from 'components/molecules';
import { DataTable, TableColumn } from 'components/organisms';

interface ListChainStore {
	chainStores?: IPagination<IChainStore[]>;
	handleListAction: (action: string, item?: IChainStore) => void;
	setPagination: (pagination: IPaginationTable) => void;
	setSearch: (searchQuery: string) => void
}

const ListChainStorePage: React.FC<ListChainStore> = (props) => {
	const {
		chainStores, handleListAction, setPagination, setSearch,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');

	return (
		<div>
			<Grid sx={{ padding: theme.spacing(3) }} container spacing={3}>
				<Grid item xs={12} md={12}>
					<DefaultButton title={t('new')} onClick={() => handleListAction(t('new'))} />
				</Grid>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<DataTable
							data={chainStores?.data || []}
							pagination
							total={chainStores?.total}
							onChange={(page, size) => setPagination({ page, size })}
							onSearching={(search) => setSearch(search)}
						>
							<TableColumn headerText="ID" render={(item) => item.id} />
							<TableColumn headerText={t('name')} render={(item) => item.name} />
							<TableColumn headerText={t('registeration')} render={(item) => <DateLabel date={item.registration} showTime />} />
							<TableColumn
								headerText={t('icon')}
								render={(item) => (
									<Image
										height={50}
										width={50}
										src={item.profileImage}
										alt={`chainstore profile image-${item.id}`}
									/>
								)}
							/>
							<TableColumn
								headerText={t('coverImage')}
								render={(item) => (
									<Image
										height={50}
										width={120}
										src={item.coverImage}
										alt={`chainstore cover image-${item.id}`}
									/>
								)}
							/>
							<TableColumn headerText={t('status')} render={(item) => <StatusLabel status={item.status} />} />
							<TableColumn
								align="right"
								render={(item) => (
									<Dropdown
										variant="contained"
										label={t('action')}
										data={[t('changeStatus'), t('edit')]}
										renderItem={(dropdownItem) => (
											{ optionValue: dropdownItem, optionLabel: dropdownItem }
										)}
										onChange={(optionLabel) => handleListAction(optionLabel, item)}
									/>
								)}
							/>
						</DataTable>
					</PaperContainer>
				</Grid>
			</Grid>

		</div>
	);
};

export default ListChainStorePage;
