import {
	ICostCenter,
	IPagination,
	IPaginationTable,
} from 'data/models';
import { Grid, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	DateLabel,
	DefaultButton,
	Dropdown,
	StatusLabel,
} from 'components/atoms';
import { PaperContainer } from 'components/molecules';
import { DataTable, TableColumn } from 'components/organisms';

interface ListCostCenter {
	costCenters?: IPagination<ICostCenter[]>;
	handleListAction: (action: string, costCenter?: ICostCenter) => void;
	setSearch: (searchString:string) => void;
	setPagination: (pagination: IPaginationTable) => void
}

const ListCostCenterPage: React.FC<ListCostCenter> = (props) => {
	const theme = useTheme();
	const {
		costCenters, handleListAction, setSearch, setPagination,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<Grid sx={{ padding: theme.spacing(3) }} container spacing={3}>
			<Grid item xs={12} md={12}>
				<DefaultButton title={t('new')} onClick={() => handleListAction(t('new'))} />
			</Grid>
			<Grid item xs={12} md={12}>
				<PaperContainer>
					<DataTable
						data={costCenters?.data || []}
						total={costCenters?.total}
						onSearching={setSearch}
						pagination
						onChange={(page, size) => setPagination({ page, size })}
					>
						<TableColumn headerText="ID" render={(item) => item.id} />
						<TableColumn headerText={t('name')} render={(item) => item.name} />
						<TableColumn headerText={t('organization')} render={(item) => item.organization.fantasyName} />
						<TableColumn headerText={t('registeration')} render={(item) => <DateLabel date={item.registration} showTime />} />
						<TableColumn headerText={t('status')} render={(item) => <StatusLabel status={item.status} />} />
						<TableColumn render={(item) => (
							<Dropdown
								variant="contained"
								label={t('action')}
								data={[t('edit'), t('changeStatus')]}
								renderItem={(dropdownItem) => (
									{ optionValue: dropdownItem, optionLabel: dropdownItem }
								)}
								onChange={(optionLabel) => { handleListAction(optionLabel, item); }}
							/>
						)}
						/>
					</DataTable>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default ListCostCenterPage;
