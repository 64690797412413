import {
	ICard,
	IPagination,
	IPaginationTable,
} from 'data/models';
import { Grid, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	CardImage,
	DefaultButton,
	Dropdown,
	StatusLabel,
} from 'components/atoms';
import { PaperContainer } from 'components/molecules';
import { DataTable, TableColumn } from 'components/organisms';

interface ListCardInterface {
	listCardAction: (action: string, card?: ICard) => void;
	cards?: IPagination<ICard[]>;
	setPagination: (pagination: IPaginationTable) => void;
	setSearch: (searchQuery: string) => void
}

const ListCardsPage: React.FC<ListCardInterface> = (props) => {
	const {
		listCardAction, cards, setPagination, setSearch,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');
	return (
		<div>
			<Grid container sx={{ padding: theme.spacing(3) }} spacing={3}>
				<Grid item xs={12} md={12}>
					<DefaultButton title={t('new')} onClick={() => listCardAction(t('new'))} />
				</Grid>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<DataTable
							data={cards?.data || []}
							total={cards?.total}
							pagination
							onSearching={(query) => {
								setSearch(query);
							}}
							onChange={(page, size) => {
								setPagination({ page, size });
							}}
						>
							<TableColumn headerText="ID" render={(item) => item.id} />
							<TableColumn headerText={t('externalId')} render={(item) => item.externalId} />
							<TableColumn headerText={t('name')} render={(item) => item.name} />
							<TableColumn headerText={t('type')} render={(item) => item?.type?.name} />
							<TableColumn render={(item) => (
								<CardImage
									src={item.image}
									alt={`${item.name} card`}
									title={item.title}
									height={45}
									width={80}
									style={{ borderRadius: '5px' }}
								/>
							)}
							/>
							<TableColumn render={(item) => <StatusLabel status={item.cardStatus} />} />
							<TableColumn
								render={(cardItem) => (
									<Dropdown
										variant="contained"
										data={[t('changeStatus'), t('details'), t('issue')]}
										label={t('action')}
										onChange={(action) => listCardAction(action, cardItem)}
										renderItem={(item) => ({ optionLabel: item, optionValue: item })}
									/>
								)}
							/>
						</DataTable>
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default ListCardsPage;
