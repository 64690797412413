import { FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import SendMessagePage from './SendMessagePage';
import { useFeedback, useTitleBar } from 'providers';
import { useRequest } from 'data/api';
import { getUserById, sendUserMessage } from 'data/api/requests/user.service';
import { useEffectCustom } from 'components/hooks';

export interface Message {
   title: string;
   message: string;
   application: number;
   pushNotification: boolean;
   email: boolean;
   sms: boolean;
}

const SendMessage = () => {
	const { setTitle } = useTitleBar();
	const { t } = useTranslation('translations');
	const { id } = useParams<{ id: string }>();
	const user = useRequest(([params]) => getUserById(params));
	const { addToast } = useFeedback();

	const [initialValues] = useState({
		title: '', message: '', application: 1, pushNotification: false, email: false, sms: false,
	});

	useEffectCustom(() => {
		setTitle(t('sendMessage'));
		user.execute(id);
	}, []);

	const validate = (values: Message) => {
		const errors: Partial<Message> = {};
		if (!values.title) {
			errors.title = t('emptyField');
		}
		if (!values.message) {
			errors.message = t('emptyField');
		}
		if (!values.pushNotification && !values.email && !values.sms) {
			errors.pushNotification = true;
		}
		return errors;
	};

	const submit = (values: Message, formikHelpers: FormikHelpers<Message>) => {
		sendUserMessage({ ...values, userId: id })
			.then((response) => {
				formikHelpers.setSubmitting(false);
				formikHelpers.resetForm({});
				addToast({ message: response.message, error: false });
			})
			.catch(({ response }) => {
				formikHelpers.setSubmitting(false);
				addToast({ message: response.data.message, error: true });
			});
	};

	return (
		<SendMessagePage
			user={user.data}
			initialValues={initialValues}
			validateForm={validate}
			submitForm={submit}
		/>
	);
};

export default SendMessage;
