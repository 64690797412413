/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import UsersReportPage from './UsersReportPage';
import { useTitleBar } from 'providers';
import { useRequest } from 'data/api';
import { getUsersReport } from 'data/api/requests/user.service';
import { formatDate } from 'utils/helpers';
import { exportUserExcel } from 'utils/export-xlsx.utils';

const FIRST_DAY_OF_CURRENT_MONTH = new Date(new Date().setDate(1));

const UsersReport = () => {
	const { setTitle } = useTitleBar();
	const { t } = useTranslation('translations');

	const userReport = useRequest(([param1, param2]) => getUsersReport(param1, param2));
	const [dateRange, setDateRange] = useState({
		startDate: FIRST_DAY_OF_CURRENT_MONTH, // Set 1st day of month as start date
		endDate: new Date(),
	});

	useEffect(() => {
		setTitle(`${t('reports')} - ${t('new')} ${t('users')}`);
	}, [setTitle, t]);

	const onChange = (key: string, value: Date) => {
		setDateRange({ ...dateRange, [key]: value });
	};

	const handleFilter = () => {
		userReport.execute(dateRange.startDate, dateRange.endDate);
	};

	const onExportClick = () => {
		if (userReport.data) {
			const header = ['ID', t('registration'), t('name'), t('email'), t('phone'), t('nif')];
			const users = userReport.data.map((row: any) => ({
				id: row.id,
				registration: formatDate(row.registration),
				name: row.name,
				email: row.email,
				phone: row.phoneNumber,
				taxId: row.taxId,
			}));
			exportUserExcel(`dados-${moment(new Date()).format('DD-MM-YYYY HH:mm')}.xlsx`, users, header);
		}
	};

	return (
		<UsersReportPage
			users={userReport.data}
			onChange={onChange}
			dates={dateRange}
			onFilterClick={handleFilter}
			loading={userReport.loading}
			onExportClick={onExportClick}
		/>
	);
};

export default UsersReport;
