import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Dashboard as DashboardIcon,
	Groups,
	LocalActivity,
	List,
	AddCircle,
	// FeaturedPlayList,
	Paid,
	// Star,
	// Campaign,
	PlaylistAddCheck,
	// Help,
	// LocalOffer,
	// Store,
	CorporateFare,
	CreditCard,
	GroupWork,
	AccountCircle,
	Store,
	// ConfirmationNumber,
	// ConnectWithoutContact,
} from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { AppRoutes } from 'components/routes/app-links.routes';

export interface UserRoutes {
   title: string;
   icon?: ReactNode;
   route: string;
   children?: { title: string; icon: ReactNode; route: string }[];
   active: boolean;
}

export const useMenuItems = () => {
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const iconStyle = {
		fontSize: 15,
		padding: 0,
		color: theme.palette.icon.main,
	};
	const DefaultUserRoutes: UserRoutes[] = [
		{
			title: t('dashboard'),
			route: AppRoutes.DASHBOARD,
			active: false,
			icon: <DashboardIcon style={iconStyle} />,
		},
		{
			title: t('users'),
			active: false,
			route: '/user',
			icon: <Groups style={iconStyle} />,
			children: [
				{
					title: t('listAll'),
					icon: <List style={iconStyle} />,
					route: AppRoutes.LIST_USERS,
				},
				{
					title: t('createNew'),
					icon: <AddCircle style={iconStyle} />,
					route: AppRoutes.NEW_USER,
				},
				{
					title: t('administrators'),
					icon: <AccountCircle style={iconStyle} />,
					route: AppRoutes.ADMINISTRATORS,
				},
			],
		},
		{
			title: t('coupons'),
			// route: AppRoutes.DASHBOARD,
			active: false,
			route: '/coupon',
			icon: <LocalActivity style={iconStyle} />,
			children: [
				{
					title: t('listAll'),
					icon: <List style={iconStyle} />,
					route: AppRoutes.LIST_COUPONS,
				},
				{
					title: t('createNew'),
					icon: <AddCircle style={iconStyle} />,
					route: AppRoutes.NEW_COUPON,
				},
			],
		},
		{
			title: t('transactions'),
			route: AppRoutes.LIST_TRANSACTIONS,
			active: false,
			icon: <Paid style={iconStyle} />,
		},
		{
			title: t('terms'),
			route: AppRoutes.TERMS,
			active: false,
			icon: <PlaylistAddCheck style={iconStyle} />,
		},
		{
			title: t('networks'),
			route: AppRoutes.CHAIN_STORES,
			active: false,
			icon: <Store style={iconStyle} />,
		},
		{
			title: t('organizations'),
			route: AppRoutes.ORGANIZATIONS,
			active: false,
			icon: <CorporateFare style={iconStyle} />,
		},
		{
			title: t('partners'),
			route: AppRoutes.PARTNERS,
			active: false,
			icon: <GroupWork style={iconStyle} />,
		},
		{
			title: t('cards'),
			active: false,
			route: '/cards',
			icon: <CreditCard style={iconStyle} />,
			children: [
				{
					title: t('listAll'),
					icon: <List style={iconStyle} />,
					route: AppRoutes.CARDS,
				},
				{
					title: t('createNew'),
					icon: <AddCircle style={iconStyle} />,
					route: AppRoutes.CREATE_CARD,
				},
			],
		},
		{
			title: t('reports'),
			active: false,
			route: '/report',
			icon: <CreditCard style={iconStyle} />,
			children: [
				{
					title: t('users'),
					icon: <Groups style={iconStyle} />,
					route: AppRoutes.USERS_REPORT,
				},
				{
					title: t('coupons'),
					icon: <LocalActivity style={iconStyle} />,
					route: AppRoutes.COUPONS_REPORT,
				},
				{
					title: t('transactions'),
					icon: <Paid style={iconStyle} />,
					route: AppRoutes.TRANSACTION_REPORT,
				},
			],
		},
	];
	const [menuItems] = useState<UserRoutes[]>(DefaultUserRoutes);

	return { menuItems };
};
