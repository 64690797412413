import {
	ApiResponse,
	IAdministrators,
	IListDataControl,
	IPagination,
	IStatus,
} from 'data/models';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';

export const promoteToAdm = async (userId?: string): Promise<ApiResponse<IAdministrators>> => {
	const resp = await reqPost({ url: Endpoint.PROMOTE_TO_ADM, data: { userId } });
	const { data } = resp;
	data.statusCode = resp.status;
	return data;
};

export const getAdministrators = async (params: IListDataControl, signal: AbortSignal):
	Promise<ApiResponse<IPagination<IAdministrators[]>>> => {
	const { page, size, search } = params;

	const resp = await reqGet({ url: Endpoint.GET_ADMINS.format(page, size, search), signal });
	const { data } = resp;
	data.statusCode = resp.status;
	return data;
};

export const changeAdminStatus = async (adminId: number): Promise<ApiResponse<IStatus>> => {
	const resp = await reqPost({ url: Endpoint.CHANGE_ADMIN_STATUS.format(adminId) });
	const { data } = resp;
	data.statusCode = resp.status;
	return data;
};
