import React from 'react';
import { useTranslation } from 'react-i18next';
import Description from '../description/Description';
import TextField from '../input/TextField';
import Title from '../title/Title';
import { FontSizes } from 'utils/constants';
import { useMask } from '@react-input/mask';

interface PhoneDailogProps {
	onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
	phoneError: string
}
const PhoneDailog:React.FC<PhoneDailogProps> = (props) => {
	const { onChange, phoneError } = props;
	const { t } = useTranslation('translations');
	const phoneInputRef = useMask({ mask: '___ ___ ___', replacement: { _: /\d/ } });

	return (
		<div>
			<Title text={t('addPhone')} />
			<Description fontSize={FontSizes.LG} text={t('userPhoneSendCoupon')} />
			<TextField
				label={t('phone')}
				inputRef={phoneInputRef}
				error={!!phoneError}
				helperText={phoneError}
				onChange={(event) => onChange(event)}
			/>
		</div>
	);
};

export default PhoneDailog;
