import {
	Box,
	createTheme,
	CssBaseline, PaletteMode, Theme, ThemeProvider, useMediaQuery,
} from '@mui/material';
import React, { useEffect } from 'react';
import {} from './utils/extensions/string.extensions';

import 'assets/localization';
import { useTranslation } from 'react-i18next';
import useLanguage from './providers/language.provider';
import LocalStorageWrapper, { LocalStorageKeys } from './utils/storage.utils';
import { useEffectCustom } from './components/hooks';
import { LoadingMessage } from './components/hocs/withSplashScreen';
import { AuthProvider, TitleBarProvider } from './providers';
import { SettingProvider } from './providers/setting.provider';
import { ErrorDialog, Toast } from './components/organisms';
import AppRouter from './components/routes/AppRouter';
import { getDesignTokens } from './utils/theme.utils';
import withContext from './components/hocs/withContext';

const App = () => {
	const [theme, setTheme] = React.useState<Theme>();
	const [mode, setMode] = React.useState<PaletteMode>();
	const { locale } = useLanguage();
	const { i18n } = useTranslation();

	const toggleColorMode = () => {
		setMode((prevMode) => {
			const nMode = prevMode === 'light' ? 'dark' : 'light';
			LocalStorageWrapper.set(LocalStorageKeys.THEME, nMode);
			return nMode;
		});
	};

	const isPrefsDark = useMediaQuery('(prefers-color-scheme: dark)');

	useEffect(() => {
		let initialMode = LocalStorageWrapper.get(LocalStorageKeys.THEME) as PaletteMode;
		if (!initialMode) {
			initialMode = isPrefsDark ? 'dark' : 'light';
		}
		setMode(initialMode);
	}, [isPrefsDark]);

	useEffectCustom(() => {
		const lang = i18n.language;
		document.documentElement.lang = lang;
		getDesignTokens(mode ?? 'light').then((th) => {
			setTheme(createTheme(th, locale));
		});
	}, [mode, locale]);

	return (
		<Box>
			{!theme ? <LoadingMessage /> : (
				<TitleBarProvider>
					<ThemeProvider theme={theme}>
						<SettingProvider updateUIMode={toggleColorMode}>

							<Toast />
							<ErrorDialog />
							<AuthProvider>
								<AppRouter />
								<CssBaseline />
							</AuthProvider>

						</SettingProvider>
					</ThemeProvider>
				</TitleBarProvider>
			)}
		</Box>
	);
};

export default withContext(App);
