import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import ListCostCenterPage from './ListCostCenterPage';
import { useTitleBar } from 'providers';
import { useRequest } from 'data/api';
import {
	changeCostCenterStatus,
	getCostCenters,
} from 'data/api/requests';
import { useEffectCustom } from 'components/hooks';
import { ICostCenter, IPaginationTable } from 'data/models';
import { AppRoutes } from 'components/routes/app-links.routes';

const ListCostCenter = () => {
	const { organizationId } = useParams();
	const { partnerId } = useParams();
	const { setTitle } = useTitleBar();
	const { t } = useTranslation('translations');
	const location = useLocation();
	const [searchParam, setSearchParam] = useSearchParams();

	const costCenters = useRequest(([param1, param2]) => getCostCenters(param1, param2));
	const costCenterStatus = useRequest(([params]) => changeCostCenterStatus(params));
	const navigate = useNavigate();

	const [pagination, setPagination] = useState({
		page: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
		size: parseInt(new URLSearchParams(location.search).get('size') || '10', 10),
	});
	const [search, setSearch] = useState(new URLSearchParams(location.search).get('search') || '');

	useEffect(() => {
		setTitle(t('costCenter'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		const getData = () => {
			if (organizationId || partnerId) {
				const listDataControl = {
					...pagination,
					search,
				};
				costCenters.execute(organizationId ?? partnerId, listDataControl);
			}
		};
		getData();
	}, [pagination, search]);

	useEffectCustom(() => {
		const { data } = costCenterStatus;
		if (data) {
			costCenters.setData((prev) => {
				const mPrev = prev;
				if (mPrev?.data) {
					const index = mPrev.data.findIndex((el) => el.id === data.id);
					if (index !== undefined) {
						mPrev.data[index] = data;
					}
				}
				return mPrev;
			});
		}
	}, [costCenterStatus.data]);

	const handleListAction = (action: string, costCenter?: ICostCenter) => {
		switch (action) {
		case t('new'):
			if (partnerId) {
				navigate(AppRoutes.CREATE_COST_CENTER_PARTNER.formatMap({ partnerId }));
			} else if (organizationId) {
				navigate(AppRoutes.CREATE_COST_CENTER_ORGANIZATION.formatMap({ organizationId }));
			}
			break;
		case t('edit'):
			if (partnerId) {
				navigate(AppRoutes.EDIT_COST_CENTER_PARTNER.formatMap({ id: costCenter?.id, partnerId }));
			} else if (organizationId) {
				navigate(AppRoutes.EDIT_COST_CENTER_ORGANIZATION.formatMap({
					id: costCenter?.id, organizationId,
				}));
			}
			break;
		case t('changeStatus'):
			costCenterStatus.execute(costCenter?.id);
			break;

		default:
			break;
		}
	};

	const setTableSearch = (searchQuery: string) => {
		setSearch(searchQuery);
		searchParam.set('search', searchQuery);
		setSearchParam(searchParam);
	};

	const setTablePagination = (paginationTable: IPaginationTable) => {
		setPagination(paginationTable);
		searchParam.set('page', paginationTable.page.toString());
		searchParam.set('size', paginationTable.size.toString());
		setSearchParam(searchParam);
	};

	return (
		<ListCostCenterPage
			costCenters={costCenters.data}
			handleListAction={handleListAction}
			setPagination={setTablePagination}
			setSearch={setTableSearch}
		/>
	);
};

export default ListCostCenter;
