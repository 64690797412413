import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { INameAndId } from 'data/models/misc.model';
import moment from 'moment';
import CouponsReportPage from './CouponsReportPage';
import { useRequest } from 'data/api';
import { useTitleBar } from 'providers';
import { getCouponReport } from 'data/api/requests';
import { ICouponReport, ICouponReportReq } from 'data/models';
import { exportUserExcel } from 'utils/export-xlsx.utils';

const FIRST_DAY_OF_CURRENT_MONTH = new Date(new Date().setDate(1)); // get 1st day of the month

const CouponsReport = () => {
	const { setTitle } = useTitleBar();
	const { t } = useTranslation('translations');
	const couponReport = useRequest(([params]) => getCouponReport(params));

	const CATEGORIES = [
		{ name: t('discount'), id: 1 },
		{ name: t('reimbursment'), id: 2 },
	];

	const [couponRequest, setCouponRequest] = useState<ICouponReportReq>({
		initialDate: FIRST_DAY_OF_CURRENT_MONTH,
		finalDate: new Date(),
		categories: CATEGORIES,
	});

	useEffect(() => {
		setTitle(`${t('reports')} - ${t('coupons')}`);
	}, [setTitle, t]);

	const handleChange = (key: string, value: Date | INameAndId[]) => {
		setCouponRequest({ ...couponRequest, [key]: value });
	};

	const handleFilterClick = () => {
		couponReport.execute(couponRequest);
	};

	const handleExportClick = () => {
		if (couponReport.data) {
			const header = [t('title'), t('limit'), t('cost'), t('bonus'), t('requested'), t('users'), t('rejected'), t('category'), t('status')];
			const coupons = couponReport.data.map((row: ICouponReport) => ({
				title: row.title,
				limit: row.maxUsage,
				cost: row.cost,
				bonus: `${row.amount}${row.isPercentage ? '%' : ' €'}`,
				requested: row.requested,
				users: row.used,
				rejected: row.rejected,
				category: row.category,
				status: row.status.name,
			}));
			exportUserExcel(`${t('coupons')}-${moment(new Date()).format('DD-MM-YYYY HH:mm')}.xlsx`, coupons, header);
		}
	};

	return (
		<CouponsReportPage
			couponReport={couponReport.data}
			categories={CATEGORIES}
			couponRequest={couponRequest}
			onChange={handleChange}
			onFilterClick={handleFilterClick}
			loading={couponReport.loading}
			onExportClick={handleExportClick}
		/>
	);
};

export default CouponsReport;
