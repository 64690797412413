import { Grid, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Summarize } from '@mui/icons-material';
import { IUser } from 'data/models';
import { PaperContainer } from 'components/molecules';
import {
	DateLabel,
	DatePicker,
	DefaultButton,
	Space,
	Title,
} from 'components/atoms';
import { DataTable, TableColumn } from 'components/organisms';

type DateRange = {
	startDate: Date;
	endDate: Date
}

interface UsersReportProps {
	users?: IUser[];
	onChange: (key: string, value: Date) => void;
	dates: DateRange;
	onFilterClick: () => void;
	loading: boolean;
	onExportClick: () => void
}

const UsersReportPage: React.FC<UsersReportProps> = (props) => {
	const {
		users, onChange, dates, onFilterClick, loading, onExportClick,
	} = props;
	const { t } = useTranslation('translations');
	const theme = useTheme();

	return (
		<div>
			<Grid sx={{ padding: theme.spacing(3) }} container spacing={3}>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<Grid container spacing={2}>
							<Grid item xs={12} md={3}>
								<DatePicker label={t('startDate')} disabled={loading} value={dates.startDate} onChange={(value) => onChange('startDate', value)} />
							</Grid>
							<Grid item xs={12} md={3}>
								<DatePicker label={t('endDate')} disabled={loading} value={dates.endDate} onChange={(value) => onChange('endDate', value)} />
							</Grid>
							<Grid item xs={12} md={3}>
								<DefaultButton title={t('filter')} onClick={onFilterClick} loading={loading} />
							</Grid>
						</Grid>
					</PaperContainer>
				</Grid>
			</Grid>
			<Grid
				sx={{
					pl: theme.spacing(3),
					pr: theme.spacing(3),
					pb: theme.spacing(3),
				}}
				container
				spacing={3}
			>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<Title text={`${t('quantity')}: ${users?.length || 0}`} />
						<Space height={2} />
						<DefaultButton title={t('export')} startIcon={<Summarize />} onClick={onExportClick} />
						<Space height={2} />

						<DataTable data={users || []} search={false} stripedRows>
							<TableColumn headerText="ID" render={(item) => item.id} />
							<TableColumn headerText={t('registeration')} render={(item) => <DateLabel date={item.registration} showTime />} />
							<TableColumn headerText={t('nif')} render={(item) => item.taxId} />
							<TableColumn headerText={t('name')} render={(item) => item.name} />
							<TableColumn headerText={t('email')} render={(item) => item.email} />
							<TableColumn headerText={t('phone')} render={(item) => item.phoneNumber} />
						</DataTable>

					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default UsersReportPage;
