import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import TransactionsListPage from './TransactionsListPage';
import { useFeedback, useTitleBar } from 'providers';
import {
	ApiResponse,
	IListDataControl,
	IPagination,
	IPaginationTable,
	ITransaction,
} from 'data/models';
import {
	cancelTransaction,
	confirmTransaction,
	getTransactions,
} from 'data/api/requests/transactions.service';
// import { AppRoutes } from 'components/routes/app-links.routes';
import { useEffectCustom } from 'components/hooks';

const Transactions = () => {
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { addToast } = useFeedback();
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParam, setSearchParam] = useSearchParams();

	const [transactions, setTransactions] = useState<IPagination<ITransaction[]>>();
	const [pagination, setPagination] = useState({
		page: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
		size: parseInt(new URLSearchParams(location.search).get('size') || '10', 10),
	});
	const [search, setSearch] = useState(new URLSearchParams(location.search).get('search') || '');

	const updateTransaction = (res: ApiResponse<ITransaction>) => {
		const index = transactions?.data?.findIndex((el) => el.id === res?.data?.id);
		if (transactions && index !== undefined) {
			const newCoupons: IPagination<ITransaction[]> = Object.create(transactions);
			newCoupons.data[index] = res.data;
			setTransactions(newCoupons);
		}
	};

	const onConfirmTransaction = (transactionId?: number) => {
		setGlobalLoading(true);
		confirmTransaction(transactionId)
			.then((res) => {
				updateTransaction(res);
				addToast({ message: res.message, error: false });
			})
			.catch(({ response }) => addToast({ message: response.data.message, error: true }))
			.finally(() => setGlobalLoading(false));
	};

	const onCancelTransaction = (transactionId?: number) => {
		setGlobalLoading(true);
		cancelTransaction(transactionId)
			.then((res) => {
				updateTransaction(res);
				addToast({ message: res.message, error: false });
			})
			.catch(({ response }) => addToast({ message: response.data.message, error: true }))
			.finally(() => setGlobalLoading(false));
	};

	const handleListAction = (action: string, itemId?: number) => {
		switch (action) {
		case t('confirm'): {
			onConfirmTransaction(itemId);
			break;
		}
		case t('cancel'): {
			onCancelTransaction(itemId);
			break;
		}
		case t('orderDetials'):
			// navigate(AppRoutes.ORDER_DETAIL.formatMap({ id: itemId }));
			break;
		default: {
			navigate('/');
			break;
		}
		}
	};

	useEffectCustom(() => {
		setTitle(t('transactions'));
		setGlobalLoading(true);
		const listControl: IListDataControl = {
			page: pagination.page,
			search,
			size: pagination.size,
		};
		getTransactions(listControl)
			.then((res) => setTransactions(res.data))
			.catch(({ response }) => addToast({ message: response.data.message, error: true }))
			.finally(() => {
				setGlobalLoading(false);
			});
	}, [search, pagination, t]);

	const setTableSearch = (searchQuery: string) => {
		setSearch(searchQuery);
		searchParam.set('search', searchQuery);
		setSearchParam(searchParam);
	};

	const setTablePagination = (paginationTable: IPaginationTable) => {
		setPagination(paginationTable);
		searchParam.set('page', paginationTable.page.toString());
		searchParam.set('size', paginationTable.size.toString());
		setSearchParam(searchParam);
	};

	return (
		<TransactionsListPage
			data={transactions}
			onPageChange={setTablePagination}
			onSearch={setTableSearch}
			handleListAction={handleListAction}
		/>
	);
};

export default Transactions;
