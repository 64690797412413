/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
	JSXElementConstructor,
} from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { useTheme } from '@mui/material';
import { box } from 'assets/lottie';

export const LoadingMessage: React.FC = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: box,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};
	return (
		<div style={{
			backgroundColor: theme.palette.background.paper,
			position: 'absolute',
			top: 0,
			bottom: 0,
			left: 10,
			right: 10,
			display: 'flex',
			flexWrap: 'wrap',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
		}}
		>
			<Lottie
				options={defaultOptions}
				height={300}
				width={300}
				style={{
					padding: 10,
				}}
			/>
			<p style={{ textAlign: 'center' }}>{t('splash')}</p>
		</div>
	);
};

const withSplashScreen = (WrappedComponent: JSXElementConstructor<any>) => (props: any) => (
	<div>
		<WrappedComponent {...props} />
	</div>
);

export default withSplashScreen;
