import { FontSizes } from 'utils/constants';
import { Brightness4 } from '@mui/icons-material';
import { IconButton, useTheme } from '@mui/material';
import React from 'react';
import useSettingContext from 'providers/setting.provider';

const ThemeToggle = () => {
	// const { isDarkMode, setThemeMode } = useAppTheme();
	const { updateUIMode } = useSettingContext();
	const theme = useTheme();
	// const switchThemeMode = () => {
	// 	if (isDarkMode) {
	// 		setThemeMode(ThemeModes.LIGHT);
	// 	} else {
	// 		setThemeMode(ThemeModes.DARK);
	// 	}
	// 	document.location.reload();
	// };
	return (
		<IconButton onClick={updateUIMode} color="primary">
			<Brightness4 style={{ color: theme.palette.icon.main, fontSize: FontSizes.LG }} />
		</IconButton>
	);
};

export default ThemeToggle;
