import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import AdministratorPage from './AdministratorPage';
import { useRequest } from 'data/api';
import { useTitleBar } from 'providers';
import { useEffectCustom } from 'components/hooks';
import { IPaginationTable } from 'data/models';
import { changeAdminStatus, getAdministrators } from 'data/api/requests';

const Administrators = () => {
	const { setTitle } = useTitleBar();
	const { t } = useTranslation('translations');
	const location = useLocation();
	const [searchParam, setSearchParam] = useSearchParams();

	const [pagination, setPagination] = useState({
		page: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
		size: parseInt(new URLSearchParams(location.search).get('size') || '10', 10),
	});
	const [search, setSearch] = useState(new URLSearchParams(location.search).get('search') || '');
	const [index, setIndex] = useState(0);

	const administrators = useRequest(([params, signal]) => getAdministrators(params, signal));
	const status = useRequest(([params]) => changeAdminStatus(params));

	useEffectCustom(() => {
		const abortCtrl = new AbortController();
		const getData = () => {
			const params = {
				page: pagination.page,
				size: pagination.size,
				search,
			};
			administrators.execute(params, abortCtrl.signal);
		};
		setTitle(t('administrators'));
		getData();
		return () => abortCtrl.abort();
	}, [pagination, search, setTitle]);

	useEffectCustom(() => {
		const { data } = status;
		if (data) {
			administrators.setData((prev) => {
				const Mprev = prev;
				if (Mprev && index !== undefined) {
					Mprev.data[index].status = data;
				}
				return prev;
			});
		}
	}, [status.data]);

	const changeStatus = (id: string) => {
		const tempIndex = administrators?.data?.data.findIndex((el) => el.id === id);
		setIndex(tempIndex ?? 56382493114758);
		status.execute(id);
	};

	const setTableSearch = (searchQuery: string) => {
		setSearch(searchQuery);
		searchParam.set('search', searchQuery);
		setSearchParam(searchParam);
	};

	const setTablePagination = (paginationTable: IPaginationTable) => {
		setPagination(paginationTable);
		searchParam.set('page', paginationTable.page.toString());
		searchParam.set('size', paginationTable.size.toString());
		setSearchParam(searchParam);
	};

	return (
		<AdministratorPage
			setPagination={setTablePagination}
			setSearch={setTableSearch}
			changeStatus={changeStatus}
			data={administrators.data}
		/>
	);
};

export default Administrators;
