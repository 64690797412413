/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFeedback } from 'providers';
import { Alert, Slide, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';

const SlideComponent = (props: any) => <Slide {...props} direction="down" />;

type Alert_Color = 'success' | 'error' | 'info' | 'warning' | undefined

const Toast = () => {
	const {
		open, toastProps, removeToast,
	} = useFeedback();
	const [color, setColor] = useState<Alert_Color>();

	useEffect(() => {
		if (toastProps?.error) {
			setColor('error');
		} else if (open) {
			setColor('success');
		}
	}, [toastProps?.error, open]);

	return (
		<div>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={open}
				key="simple-toast"
				autoHideDuration={3000}
				TransitionComponent={SlideComponent}
				onClose={removeToast}
			>
				<Alert severity={color} color={color}>{toastProps?.message}</Alert>
			</Snackbar>
		</div>
	);
};

export default Toast;
