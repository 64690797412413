/* eslint-disable @typescript-eslint/no-explicit-any */
import { DefaultButton } from 'components/atoms';
import { CheckCircle, Error } from '@mui/icons-material';
import {
	Dialog as DialogMui,
	Slide,
	DialogTitle,
	useTheme,
	DialogContent,
	DialogContentText,
	DialogActions,

} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

const ICON_STRING = {
	success: 'success',
	error: 'error',
};

interface DialogProps {
	icon?: 'success' | 'error';
	open: boolean;
	handleClose: () => void;
	title?: string | React.ReactNode;
	description?: string | React.ReactNode;
	primaryButtonText?: string;
	secondaryButtonText?: string;
	primaryButtonAction?: () => void;
	secondaryButtonAction?: () => void
}

const Transition = React.forwardRef((
	props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
	ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

const Dialog: React.FC<DialogProps> = (props) => {
	const {
		open,
		handleClose,
		icon,
		title,
		description,
		primaryButtonText,
		primaryButtonAction,
		secondaryButtonAction,
		secondaryButtonText,
	} = props;
	const theme = useTheme();
	return (
		<div>
			<DialogMui
				sx={{ paddingLeft: theme.spacing(5), paddingRight: theme.spacing(5) }}
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-describedby="alert-dialog-slide-description"
			>
				{icon && (icon === ICON_STRING.success
					? (
						<DialogTitle sx={{ textAlign: 'center' }}>
							<CheckCircle color="success" fontSize="large" />
						</DialogTitle>
					)
					: (
						<DialogTitle sx={{ textAlign: 'center' }}>
							<Error color="error" fontSize="large" />
						</DialogTitle>
					))}
				<DialogTitle sx={{
					textAlign: 'center',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
				>
					{title}
				</DialogTitle>
				<DialogContent sx={{ textAlign: 'center' }}>
					{typeof description === 'string' ? (
						<DialogContentText id="alert-dialog-description">
							{`${description}`}
						</DialogContentText>
					) : description}

				</DialogContent>
				<DialogActions>
					<DefaultButton
						title={primaryButtonText || 'OK'}
						onClick={() => primaryButtonAction?.call(0) || handleClose()}
					/>
					{secondaryButtonText && (
						<DefaultButton
							color="secondary"
							title={secondaryButtonText}
							onClick={() => secondaryButtonAction?.call(0)}
						/>
					)}
				</DialogActions>
			</DialogMui>
		</div>
	);
};

export default Dialog;
