import {
	ICardUser,
	CardUserResponse,
	ICard,
	ICardReq,
	IBillingType,
	IIssueCardReq,
} from 'data/models/card.model';
import Endpoint from '../endpoints.api';
import request, { reqGet, reqPost } from '../request.api';
import {
	ApiResponse,
	IFileResponse,
	IListDataControl,
	INameAndId,
	IPagination,
	IStatus,
} from 'data/models';
import { normalize, normalizeCurrency } from 'utils/masks';
import { CARD_STATUSES } from 'utils/constants';

export const uploadCardImage = async (file: FormData): Promise<ApiResponse<IFileResponse>> => {
	const resp = await request({ url: Endpoint.UPLOAD_CARD_IMAGE, method: 'POST', data: file });
	const { data } = resp;
	data.statusCode = resp.status;
	return data;
};

export const getAllCards = async (params: IListDataControl, signal?: AbortSignal):
	Promise<ApiResponse<IPagination<ICard[]>>> => {
	const { page, size, search } = params;
	const resp = await reqGet({
		url: Endpoint.CARDS.format(page, size, search),
		signal,
	});
	const data = resp.data as ApiResponse<IPagination<ICard[]>>;
	data.statusCode = resp.status;
	return data;
};

export const getCardById = async (cardId: string | undefined, signal: AbortSignal):
	Promise<ApiResponse<ICard>> => {
	const resp = await reqGet({
		url: Endpoint.CARD_BY_ID.format(cardId),
		signal,
	});
	const data = resp.data as ApiResponse<ICard>;
	data.statusCode = resp.status;
	return data;
};

export const createUserCard = async (card: ICardReq): Promise<ApiResponse<ICard>> => {
	let { cardImage } = card;
	if (typeof cardImage === 'object') {
		if (cardImage.size) {
			const data = new FormData();
			data.append('file', cardImage);
			const res = await uploadCardImage(data);
			cardImage = res.data.url;
		}
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const cardToUpload: any = {
		...card,
		cardTypeId: card.cardTypeId?.id,
		organizationId: card.organizationId?.id,
		categoryId: card.categoryId?.id,
		billingTypeId: card.billingTypeId?.id,
		cardLimit: normalizeCurrency(card.cardLimit) || undefined,
		cardImage,
	};
	if (!card.webhookUri) {
		delete cardToUpload.webhookUri;
	}
	const resp = await reqPost({
		url: Endpoint.ADD_CARD,
		data: { ...cardToUpload },
	});
	const data = resp.data as ApiResponse<ICard>;
	data.statusCode = resp.status;
	return data;
};

export const editCard = async (cardId: string, card: ICardReq): Promise<ApiResponse<ICard>> => {
	let { cardImage } = card;
	if (typeof cardImage === 'object') {
		if (cardImage.size) {
			const data = new FormData();
			data.append('file', cardImage);
			const res = await uploadCardImage(data);
			cardImage = res.data.url;
		}
	}
	const cardToUpload = {
		...card,
		cardTypeId: card.cardTypeId?.id,
		organizationId: card.organizationId?.id,
		categoryId: card.categoryId?.id,
		billingTypeId: card.billingTypeId?.id,
		cardLimit: normalizeCurrency(card.cardLimit),
		cardImage,
	};
	const resp = await reqPost({
		url: Endpoint.EDIT_CARD.format(cardId),
		data: { ...cardToUpload },
	});
	const data = resp.data as ApiResponse<ICard>;
	data.statusCode = resp.status;
	return data;
};

export const changeCardStatus = async (cardId: number | undefined, cardStatus?: number):
	Promise<ApiResponse<ICard>> => {
	const resp = await reqPost({
		url: Endpoint.CARD_STATUS.format(cardId),
		data: { cardStatus },
	});
	const data = resp.data as ApiResponse<ICard>;
	data.statusCode = resp.status;
	return data;
};

export const getAllStatus = async (): Promise<ApiResponse<IStatus[]>> => {
	const resp = await reqGet({
		url: Endpoint.CARD_ALL_STATUSES,
	});
	const data = resp.data as ApiResponse<IStatus[]>;
	data.statusCode = resp.status;
	return data;
};

export const getCardCategories = async (signal: AbortSignal):
 Promise<ApiResponse<INameAndId[]>> => {
	const resp = await reqGet({
		url: Endpoint.CARD_CATEGORIES,
		signal,
	});
	const data = resp.data as ApiResponse<INameAndId[]>;
	data.statusCode = resp.status;
	return data;
};

export const getCardTypes = async (signal: AbortSignal): Promise<ApiResponse<INameAndId[]>> => {
	const resp = await reqGet({
		url: Endpoint.CARD_TYPES,
		signal,
	});
	const data = resp.data as ApiResponse<INameAndId[]>;
	data.statusCode = resp.status;
	return data;
};

export const getBillingTypes = async (signal: AbortSignal):
 Promise<ApiResponse<IBillingType[]>> => {
	const resp = await reqGet({
		url: Endpoint.CARD_BILLING_TYPES,
		signal,
	});
	const data = resp.data as ApiResponse<IBillingType[]>;
	data.statusCode = resp.status;
	return data;
};

export const getCardUsers = async (cardId: string, params: IListDataControl, signal: AbortSignal):
	Promise<ApiResponse<CardUserResponse>> => {
	const resp = await reqGet({
		url: Endpoint.CARD_USERS.format(cardId, params.page, params.size, params.search),
		signal,
	});
	const data = resp.data as ApiResponse<IPagination<ICardUser[]>>;
	data.statusCode = resp.status;
	const mData = { ...data } as ApiResponse<CardUserResponse>;

	mData.data.issued = mData.data.total;

	mData.data.blocked = mData.data.data.reduce((mBlocked, cardUser) => {
		let tempBlocked = mBlocked;
		if (cardUser.status.id === CARD_STATUSES.BLOCKED_BY_COMPANY
			|| cardUser.status.id === CARD_STATUSES.BLOCKED_BY_USER
			|| cardUser.status.id === CARD_STATUSES.SECURITY_BLOCK) {
			tempBlocked += 1;
		}
		return tempBlocked;
	}, 0 as number);

	return mData;
};

export const changeUserCardStatus = async (cardUserId: number, statusId: number):
	Promise<ApiResponse<ICardUser>> => {
	const resp = await reqPost({
		url: Endpoint.USER_CARD_STATUS.format(cardUserId),
		data: { statusId },
	});
	const data = resp.data as ApiResponse<ICardUser>;
	data.statusCode = resp.status;
	return data;
};

export const issueCardToUser = async (
	params?: IIssueCardReq,
): Promise<ApiResponse<ICardUser>> => {
	let mReqData: any = {
		cardId: params?.cardId,
		cardTypeId: params?.cardTypeId,
		userId: params?.userId,
		name: params?.cardName,
	};
	if (params?.cardTypeId === 4) {
		mReqData = {
			...mReqData,
			cardType2Model: null,
			cardType4Model: {
				name: params.userName,
				taxId: normalize(params.taxId || ''),
				taxIdCountryId: params.countryId?.id,
				neighborhood: params.neighborhood,
				address: params.address,
				postalCode: params.postCode,
				carPlate: params.carPlates?.toUpperCase(),
			},
		};
	} else {
		mReqData = {
			...mReqData,
			cardType2Model: {
				externalId: params?.externalId,
			},
			cardType4Model: null,
		};
	}
	const resp = await reqPost({
		url: Endpoint.ISSUE_CARD,
		data: { ...mReqData },
	});
	const data = resp.data as ApiResponse<ICardUser>;
	return data;
};
