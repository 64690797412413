import { FormikErrors, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import RichTextEditor from 'react-rte';
import CreateCouponPage from './CreateCouponPage';
import { useFeedback, useTitleBar } from 'providers';
import {
	createCoupon,
	editCoupon,
	getCouponById,
	getOrganizations,
} from 'data/api/requests';
import { useRequest } from 'data/api';
import { ICreateCouponReq, IOrganization } from 'data/models';
import { useEffectCustom } from 'components/hooks';
import { putDecimalPoints, replaceComma } from 'utils/masks';
import { AppRoutes } from 'components/routes/app-links.routes';

const CreateCoupon = () => {
	const [loading, setLoading] = useState(false);
	const { setTitle } = useTitleBar();
	const { t } = useTranslation('translations');
	const { addToast, addDialog } = useFeedback();
	const navigate = useNavigate();
	const { id } = useParams<{ id: string }>();

	const organizations = useRequest(([params, signal]) => getOrganizations(params, signal));
	const [initialValues, setInitialValues] = useState<ICreateCouponReq>({
		title: '',
		description: '',
		banner: '',
		startDate: new Date(),
		endDate: new Date(),
		statusId: 1,
		maxUsage: 1,
		amount: '',
		cost: '',
		isPercentage: false,
		reusable: false,
		automatedAudience: false,
		categoryId: 1,
		maxPurchaseAmount: '',
		minPurchaseAmount: '',
		regulation: RichTextEditor.createEmptyValue(),
		organizations: [] as IOrganization[],
		file: {} as File,
	});
	const [isEdit, setEdit] = useState(false);

	useEffectCustom(() => {
		const abortCtrl = new AbortController();
		const getData = () => {
			const listDataControl = {
				page: 1,
				size: 100000,
				search: '',
			};
			organizations.execute(listDataControl, abortCtrl.signal);
			if (id) {
				setLoading(true);
				setTitle(`${t('couponDetails')}`);
				getCouponById(id, abortCtrl.signal)
					.then(({ data }) => {
						setInitialValues({
							...initialValues,
							title: data.title,
							description: data.description,
							banner: data.banner,
							startDate: new Date(data.startDate),
							endDate: new Date(data.endDate),
							statusId: 1,
							maxUsage: data.maxUsage,
							amount: data.isPercentage ? data.amount.toString() : putDecimalPoints(data.amount),
							cost: putDecimalPoints(data.cost),
							isPercentage: data.isPercentage,
							reusable: data.reusable,
							automatedAudience: data.automatedAudience,
							categoryId: data.category.id,
							maxPurchaseAmount: putDecimalPoints(data.maxPurchaseAmount),
							minPurchaseAmount: putDecimalPoints(data.minPurchaseAmount),
							regulation: RichTextEditor.createValueFromString(data.regulation, 'html'),
							organizations: data.organizations,
						});
					})
					.catch(({ response }) => {
						addToast({ message: response.data.message, error: true });
					})
					.finally(() => setLoading(false));
			} else {
				setEdit(true);
				setTitle(`${t('new')} ${t('coupons')}`);
			}
		};
		getData();
		return () => abortCtrl.abort();
	}, []);

	const validate = (values: ICreateCouponReq) => {
		const errors: FormikErrors<ICreateCouponReq> = {};
		const amount = replaceComma(values.amount);
		if (!values.title) {
			errors.title = t('emptyField');
		}
		if (!values.description) {
			errors.description = t('emptyField');
		}
		if (!values.startDate) {
			errors.startDate = t('emptyField');
		}
		if (!values.endDate) {
			errors.endDate = t('emptyField');
		}
		if (values.endDate < values.startDate) {
			errors.endDate = t('endDateError');
		}
		if (!values.amount) {
			errors.amount = t('emptyField');
		}
		if (amount === 0) {
			errors.amount = t('notZero');
		}
		if (!values.maxUsage) {
			errors.maxUsage = t('emptyField');
		}
		if (!values.cost) {
			errors.cost = t('emptyField');
		}
		if (!values.minPurchaseAmount) {
			errors.minPurchaseAmount = t('emptyField');
		}
		if (!values.maxPurchaseAmount) {
			errors.maxPurchaseAmount = t('emptyField');
		}
		return errors;
	};

	const createCoupons = (
		values: ICreateCouponReq,
		formikHelpers: FormikHelpers<ICreateCouponReq>,
	) => {
		if (id) {
			editCoupon(id, values)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.LIST_COUPONS);
				})
				.catch(({ response }) => {
					addDialog({
						title: response?.data?.title,
						message: response?.data?.message,
						error: true,
					});
				})
				.finally(() => formikHelpers.setSubmitting(false));
		} else {
			createCoupon(values)
				.then((res) => {
					formikHelpers.resetForm({});
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.LIST_COUPONS);
				})
				.catch(({ response }) => {
					addDialog({
						title: response?.data?.title,
						message: response?.data?.message,
						error: true,
					});
				})
				.finally(() => {
					formikHelpers.setSubmitting(false);
				});
		}
	};

	const handleEditClick = () => {
		setTitle(`${t('edit')} ${t('coupons')}`);
		setEdit(true);
	};

	const handleBackClick = () => {
		navigate(AppRoutes.LIST_COUPONS);
	};

	return (
		<CreateCouponPage
			createCoupons={createCoupons}
			validate={validate}
			loading={loading}
			initialValues={initialValues}
			organizations={organizations.data?.data}
			isEdit={isEdit}
			handleEditClick={handleEditClick}
			handleBackClick={handleBackClick}
		/>
	);
};

export default CreateCoupon;
