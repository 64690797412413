import {
	ApiResponse,
	IListDataControl,
	IPagination,
	IUser,
	IUserReq,
} from 'data/models';
import { normalize, normalizeCurrency, normalizePhone } from 'utils/masks';
import { ICostCenter } from 'data/models/cost-center';
import { IMessage } from 'data/models/message.model';
import { IBalence } from 'data/models/balence.model';
import { ICredit } from 'data/models/credit.model';
import { encodeDate } from 'utils/helpers';
import Endpoint from '../endpoints.api';
import { reqDelete, reqGet, reqPost } from '../request.api';
import { uploadFile } from './file.service';
import { IUserByPhone } from 'data/models/user.model';

export const getUsers = async (
	params: IListDataControl,
	signal: AbortSignal,
	costCenterId?: number|string,
):
   Promise<ApiResponse<IPagination<IUser[]>>> => {
	const { page = 1, size = 5000, search = '' } = params;

	const resp = await reqGet({
		url: Endpoint.GET_USERS.format(costCenterId || '', page, size, search),
		signal,
	});
	const { data } = resp;
	data.statusCode = resp?.status;
	return data;
};

export const changeUserStatus = async (id: number): Promise<ApiResponse<IUser>> => {
	const resp = await reqPost({ url: Endpoint.USER_STATUS.format(id) });
	const { data } = resp;
	data.statusCode = resp?.status;
	return data;
};

export const getUserById = async (id: string): Promise<ApiResponse<IUser>> => {
	const resp = await reqGet({ url: Endpoint.GET_USER_BY_ID.format(id) });
	const { data } = resp;
	data.statusCode = resp?.status;
	return data;
};

export const addNewUser = async (user: IUserReq): Promise<ApiResponse<IUser>> => {
	const dataToUpload = {
		name: user.name,
		email: user.email,
		phone: normalizePhone(user.phone),
		taxId: normalize(user.taxId),
		cityId: user?.city?.id,
		stateId: user?.state?.id,
		countryId: user?.country?.id,
	};
	if (user.country?.id !== 1) {
		delete dataToUpload.stateId;
		delete dataToUpload.cityId;
	}
	const resp = await reqPost({ url: Endpoint.USER, data: dataToUpload });
	const { data } = resp;
	data.statusCode = resp?.status;
	return data;
};

export const updateUser = async (user: IUserReq, userId: string): Promise<ApiResponse<IUser>> => {
	let downlink = '';
	if (typeof user.profileImage === 'object') {
		if (user.profileImage?.size) {
			const image = new FormData();
			image.append('file', user.profileImage);
			const res = await uploadFile(image);
			downlink = res.data.file;
		}
	}
	const dataToUpload = {
		name: user.name,
		email: user.email,
		phone: normalizePhone(user.phone),
		cityId: user?.city?.id,
		stateId: user?.state?.id,
		countryId: user?.country?.id,
		userId,
		profileImage: downlink,
	};

	const resp = await reqPost({ url: Endpoint.EDIT_USER_BY_ID.format(userId), data: dataToUpload });
	const { data } = resp;
	data.statusCode = resp?.status;
	return data;
};

export const getUserCostCenters = async (userId: string): Promise<ApiResponse<ICostCenter[]>> => {
	const resp = await reqGet({ url: Endpoint.USER_CC.format(userId) });
	const { data } = resp;
	data.statusCode = resp?.status;
	return data;
};

export const addUserToCostCenter = async (userId: string | undefined, costCenterId: number):
   Promise<ApiResponse<ICostCenter>> => {
	const resp = await reqPost({ url: Endpoint.USER_CC.format(userId), data: { costCenterId } });
	const { data } = resp;
	data.statusCode = resp?.status;
	return data;
};

export const removeUserFromCC = async (userId: string | undefined, costCenterId: number):
   Promise<ApiResponse<void>> => {
	const resp = await reqDelete({ url: Endpoint.REMOVE_FROM_CC.format(userId, costCenterId) });
	const { data } = resp;
	data.statusCode = resp?.status;
	return data;
};

export const sendUserMessage = async (params: IMessage):
   Promise<ApiResponse<void>> => {
	const modals: number[] = [];
	if (params.pushNotification) {
		modals.push(1);
	}
	if (params.email) {
		modals.push(2);
	}
	if (params.sms) {
		modals.push(3);
	}
	const dataToUpload = {
		userId: params.userId,
		message: params.message,
		title: params.title,
		modals,
	};
	const resp = await reqPost({
		url: Endpoint.SEND_MESSAGE.format(params.userId),
		data: dataToUpload,
	});
	const { data } = resp;
	data.statusCode = resp?.status;
	return data;
};

export const getUserBalence = async (userId: string): Promise<ApiResponse<IBalence>> => {
	const resp = await reqGet({ url: Endpoint.USER_BALENCE.format(userId) });
	const { data } = resp;
	data.statusCode = resp?.status;
	return data;
};

export const addCredit = async (
	{ mAmount, types, userId }: { mAmount: string; types: string; userId: string },
):
Promise<ApiResponse<ICredit>> => {
	const amount = types === '2' ? normalizeCurrency(mAmount) : mAmount;
	const type = types === '1' ? 2 : 1;
	const resp = await reqPost({
		url: Endpoint.ADD_CREDIT.format(userId),
		data: { amount, types: type },
	});
	const { data } = resp;
	data.statusCode = resp?.status;
	return data;
};

// export const getUserTags = async (userId: string): Promise<ApiResponse<ITag[]>> => {
// 	const resp = await reqGet({ url: Endpoint.USER_TAG.format(userId) });
// 	const { data } = resp;
// 	data.statusCode = resp?.status;
// 	return data;
// };

// export const addUserTag = async (userId: string | undefined, tagId: number):
// 	Promise<ApiResponse<ITag>> => {
// 	const resp = await reqPost({ url: Endpoint.USER_TAG.format(userId), data: { tagId } });
// 	const { data } = resp;
// 	data.statusCode = resp?.status;
// 	return data;
// };

export const deleteUserTag = async (userId: string | undefined, tagId: number):
	Promise<ApiResponse<void>> => {
	const resp = await reqDelete({ url: Endpoint.REMOVE_USER_TAG.format(userId, tagId) });
	const { data } = resp;
	data.statusCode = resp?.status;
	return data;
};

export const getUsersReport = async (initialDate: Date, finalDate: Date):
	Promise<ApiResponse<IUser[]>> => {
	const encodedInitialDate = encodeDate(initialDate);
	const encodedFinalDate = encodeDate(finalDate);
	const resp = await reqGet({
		url: Endpoint.USER_REPORT.format(encodedInitialDate, encodedFinalDate),
	});
	const { data } = resp;
	data.statusCode = resp?.status;
	return data;
};

export const exportUsersReport = async (initialDate: Date, finalDate: Date):
	Promise<ApiResponse<string>> => {
	const encodedInitialDate = encodeDate(initialDate);
	const encodedFinalDate = encodeDate(finalDate);
	const resp = await reqGet({
		url: Endpoint.USER_REPORT_EXPORT.format(encodedInitialDate, encodedFinalDate),
	});
	const data = { data: resp.data, statusCode: resp.status } as ApiResponse<string>;
	return data;
};

export const getUserByPhone = async (mPhone: string): Promise<ApiResponse<IUserByPhone>> => {
	const phone = normalizePhone(mPhone);
	const resp = await reqGet({
		url: Endpoint.USER_BY_PHONE.format(phone),
	});
	const data = resp.data as ApiResponse<IUserByPhone>;
	data.statusCode = resp.status;
	return data;
};

export const resetPassword = async (userId: number): Promise<ApiResponse<IUser>> => {
	const resp = await reqPost({
		url: Endpoint.USER_RESET_PASSWORD.format(userId),
	});
	const data = resp.data as ApiResponse<IUser>;
	data.statusCode = resp.status;
	return data;
};
