import {
	ApiResponse,
	ICreateTermsReq,
	IListDataControl,
	IPagination,
	ITerm,
} from 'data/models';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';

export const getTerms = async (props: IListDataControl):
    Promise<ApiResponse<IPagination<ITerm[]>>> => {
	const { page, size, search } = props;

	const resp = await reqGet({
		url: Endpoint.GET_TERMS.format(page, size, search),
	});
	const data = resp.data as ApiResponse<IPagination<ITerm[]>>;
	data.statusCode = resp?.status;
	return data;
};

export const getTermById = async (orderId: string): Promise<ApiResponse<ITerm>> => {
	const resp = await reqGet({
		url: Endpoint.EDIT_TERM.format(orderId),
	});
	const data = resp.data as ApiResponse<ITerm>;
	data.statusCode = resp?.status;
	return data;
};

export const createTerm = async (model: ICreateTermsReq): Promise<ApiResponse<ITerm>> => {
	const resp = await reqPost({
		url: Endpoint.TERMS,
		data: { ...model },
	});
	const data = resp.data as ApiResponse<ITerm>;
	data.statusCode = resp?.status;
	return data;
};

export const editTerm = async (id: number | string, model: ICreateTermsReq):
	Promise<ApiResponse<ITerm>> => {
	const resp = await reqPost({
		url: Endpoint.EDIT_TERM.format(id),
		data: { ...model },
	});
	const data = resp.data as ApiResponse<ITerm>;
	data.statusCode = resp?.status;
	return data;
};
