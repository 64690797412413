import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ForgotPasswordPage from './ForgotPasswordPage';
import { useFeedback } from 'providers';
import { EMAIL_PATTERN } from 'utils/constants';
import { forgotPassword } from 'data/api/requests/auth.service';

const ForgotPassword = () => {
	const { t } = useTranslation('translations');
	const { addToast, addDialog } = useFeedback();

	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');
	const [loading, setLoading] = useState(false);

	const handleChange = (mEmail: string) => {
		setEmail(mEmail);
	};

	const handleSubmit = () => {
		if (!email) {
			return setEmailError(t('emptyField'));
		}
		if (!EMAIL_PATTERN.test(email)) {
			return setEmailError(t('invalidEmail'));
		}
		setLoading(true);
		setEmailError('');
		forgotPassword(email)
			.then((res) => addToast({ message: res.message, error: false }))
			.catch(({ response }) => addDialog({
				title: response.data.title,
				message: response.data.message,
				error: true,
			}))
			.finally(() => setLoading(false));
		return null;
	};

	return (
		<ForgotPasswordPage
			email={email}
			error={emailError}
			handleChange={handleChange}
			handleSubmit={handleSubmit}
			loading={loading}
		/>
	);
};

export default ForgotPassword;
