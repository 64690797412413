import { logo } from 'assets/image';
import {
	Description,
	Image,
	Space,
	Title,
} from 'components/atoms';
import {
	Box, CircularProgress, Container, Stack, Typography,
} from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type NameAndEmail = {name: string, email: string}

interface ChangeEmailPage {
	data?: NameAndEmail;
	expired?: boolean;
	loading: boolean
}

const ChangeEmailPage: FC<ChangeEmailPage> = (props) => {
	const { data, expired, loading } = props;
	const { t } = useTranslation('translations');
	return (
		<Container>
			<Container maxWidth="xs">
				<Space height={5} />

				<Image src={logo} height="100%" width="100%" alt="AJ Rent Logo" />
				<Space height={2} />
				{loading && <Stack direction="column" alignItems="center"><CircularProgress /></Stack>}
				{!expired && !loading && (
					<Stack direction="column" alignItems="center">
						<Title text={t('hello').format(data?.name)} />
						<Space height={1} />
						<Description text={t('emailValidationMessage').format(data?.email)} textAlign="center" />
					</Stack>
				)}
				{expired && !loading && (
					<Stack direction="column" alignItems="center">
						<Title text={t('expiredLink')} textAlign="center" />
						<Space height={1} />
						<Typography variant="caption" sx={{ textAlign: 'center', alignSelf: 'center' }}>{t('expiredLinkDescription')}</Typography>
					</Stack>
				)}

				<Space height={3} />

				<Box display="flex" justifyContent="center">
					<Typography variant="caption">
						{process.env.REACT_APP_NAME}
						{' '}
						-
						{' '}
						{t('copyright')}
						{' '}
						©
						{' '}
						{new Date().getFullYear()}
					</Typography>
				</Box>
			</Container>
		</Container>
	);
};

export default ChangeEmailPage;
