/* eslint-disable @typescript-eslint/no-explicit-any */
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { FC } from 'react';
import { FormHelperText, useTheme } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

interface DatePickerProps {
	label?: string;
	value?: any;
	disabled?: boolean;
	onChange?: (date: any) => void;
	error?: boolean;
	helperText?: string;
	minDate?: any;
	maxDate?: any
}

const DatePicker: FC<DatePickerProps> = (props) => {
	const {
		label, value, disabled, onChange, error, helperText, minDate, maxDate,
	} = props;
	const theme = useTheme();
	// const { localeFns } = useLanguage();
	// const { t } = useTranslation('translations');
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<MobileDatePicker
				label={label}
				value={dayjs(value)}
				disabled={disabled}
				minDate={minDate ? dayjs(minDate) : undefined}
				maxDate={maxDate ? dayjs(maxDate) : undefined}
				slotProps={{
					textField: {
						fullWidth: true,
						size: 'small',
						sx: {
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: theme.palette.divider,
									transition: 'border-color 0.3s',
								},
							},
							'&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
								borderColor: theme.palette.primary.main,
							},
						},
					},
				}}
				onChange={(date) => onChange?.call(0, date)}
			/>
			<FormHelperText error={error}>{helperText}</FormHelperText>
		</LocalizationProvider>
	);
};

export default DatePicker;
